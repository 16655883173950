<template>
  <div
    v-if="state.isChargingStripeSetupPaymentIntent"
    class="widget-spinner"
  ></div>
  <w-external
    v-else
    :state="state"
    :heading="txtHeading"
    :details="txtDetails"
    :cancelled="true"
  >
    <b-message v-if="state.paymentError" type="is-danger">
      {{ state.paymentError }}
    </b-message>
  </w-external>
</template>

<script>
import i18n from '../i18n';
import External from './External.vue';

export default {
  components: {
    'w-external': External,
  },
  props: ['state', 'heading', 'details'],
  data: function () {
    return {
      txtHeading: i18n.gettext('Deposit payment'),
    };
  },
  computed: {
    txtDetails() {
      const { depositStatus, depositAmount, depositCurrency } = this.state;
      return depositStatus === 'setup_waiting'
        ? i18n.sprintf(
            i18n.gettext(
              'Please confirm your deposit payment of %s for the following booking:'
            ),
            `${depositAmount} ${depositCurrency}`
          )
        : i18n.gettext('Thank your for confirming your deposit payment!');
    },
  },
};
</script>
