<template>
  <w-external :state="state" :heading="txtHeading" :details="txtDetails">
    <template v-if="hasDeposit" v-slot:details>
      <div style="text-align: center">
        {{ txtDeposit }}
        <div style="font-size: 2rem; font-weight: 600; margin: 1rem 0">
          {{ txtDepositAmount }}
        </div>
        {{ txtDetails }}
      </div>
    </template>
    <w-input
      v-if="isBooking"
      autofocus
      type="text"
      v-model="state.cancellationReason"
      :placeholder="txtReason"
      :disabled="state.isSavingBooking"
      @keydown.native.prevent.enter="state.cancelWaitlistOrBooking"
    />
  </w-external>
</template>

<script>
import i18n from '../i18n';

import External from './External.vue';
import InputField from './common/FormInputField.vue';

export default {
  components: {
    'w-external': External,
    'w-input': InputField,
  },
  props: ['state'],
  data: function () {
    return {
      txtReason: i18n.gettext('Cancellation reason'),
    };
  },
  computed: {
    isBooking: function () {
      return this.state.booking.ref_num !== null;
    },
    hasDeposit: function () {
      return !!this.state.booking.booking_deposit;
    },
    txtHeading: function () {
      if (this.isBooking) {
        return i18n.gettext('Cancel your booking');
      } else {
        return i18n.gettext('Remove your request from the waitlist');
      }
    },
    txtDetails: function () {
      if (this.isBooking) {
        return i18n.gettext('Are you sure you want to cancel your booking?');
      } else {
        return i18n.gettext(
          'Are you sure you want to remove your request from the waitlist?'
        );
      }
    },
    txtDeposit: function () {
      return i18n.gettext('Because of late cancellation you will be charged');
    },
    txtDepositAmount: function () {
      const { amount, currency } = this.state.booking.booking_deposit;
      return `${amount} ${currency}`;
    },
  },
};
</script>
