export const getHostname = url => {
  try {
    return new URL(url).hostname
  } catch {
    return url
  }
}

export const encodeFilenameInUrl = url => {
  // encode filename of an uploaded file
  // https://www.example.com/uploads/images/& &+Ü.jpg  -->
  // https://www.example.com/uploads/images/%26%20%26%2B%C3%9C.jpg
  // let url = 'https://resmio-dev-static-files.s3.amazonaws.com/uploads/patterson-burton/images/& &+Ü.jpg'
  try {
    let splitUrl = url.split('//')
    let pathname = splitUrl[1]
    let splitPathname = pathname.split('/')
    let filenamePos = splitPathname.length - 1
    let filename = splitPathname[filenamePos]
    splitPathname[filenamePos] = encodeURIComponent(filename)
    splitUrl[1] = splitPathname.join('/')
    return splitUrl.join('//')
  } catch {
    return url
  }
}
