<template>
  <div>
    <div class="modal-header">
      <h1 class="modal-title">{{ title }}</h1>
      <button
        type="button"
        class="modal-close-button"
        @click="$emit('close', 'escape')"
      ></button>
    </div>
    <w-image
      v-if="imageSrc"
      :state="state"
      :src="imageSrc"
      imageClass="modal-img"
      alt="modal_image"
      :dimensions="{ width: 500 }"
    ></w-image>
    <p
      v-if="description"
      class="modal-description"
      v-html="formattedDescription"
    ></p>
    <button
      v-if="showButton"
      ref="promoButton"
      v-on:click="handleBookingClick"
      class="modal-button button is-primary"
    >
      {{ txtModalButton }}
    </button>
  </div>
</template>

<script>
import i18n from '../i18n';
import Image from './common/Image.vue';

export default {
  components: {
    'w-image': Image,
  },
  props: [
    'state',
    'title',
    'description',
    'imageSrc',
    'showButton',
    'waitlistModal',
    'hasQueuedUpModal', // breaks out of the normal modal flow which would redirect to a new step when the button is clicked
  ],
  computed: {
    txtModalButton: function () {
      if (this.waitlistModal) {
        return i18n.gettext('Next');
      } else {
        return i18n.gettext('Book now');
      }
    },
    formattedDescription: function () {
      return this.convertUrlsToLinks(this.description);
    },
  },
  mounted() {
    if (this.state.fullscreen) {
      this.$refs.promoButton?.focus();
    }
  },
  methods: {
    handleBookingClick: function () {
      this.$emit('close');
      if (!this.hasQueuedUpModal) {
        this.state.stepForward = true;
        if (this.state.displayCustomFieldsStep()) {
          this.state.step = 'CUSTOMFIELDS';
        } else {
          this.state.step = 'CONTACT';
        }
      }
    },
    convertUrlsToLinks: function (text) {
      const urlRegex = /(https?:\/\/)[^\s]+/gi;
      return text.replace(urlRegex, (url) => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });
    },
  },
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';

.booking-closed-modal {
  .modal-description {
    padding-bottom: 2.5rem;
  }
}

.booking-options-promotion-modal {
  .modal-background {
    background: rgba(0, 0, 0, 0.5);
  }
  &.mobile-view-modal {
    .modal-background {
      background: transparent;
      backdrop-filter: blur(1px);
      animation: fadeInBackground 0.3s ease 0.4s forwards;
    }
  }
  @keyframes fadeInBackground {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
      background: rgba(0, 0, 0, 0.5);
    }
  }
  .modal-content {
    justify-content: center;
    background-color: var(--theme-background-color);
    max-width: 450px !important;
    max-height: 100vh;
    padding: 1.5rem;
    border-radius: 5px;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .modal-title {
      color: var(--theme-accent-color);
      align-self: flex-start;
      font-size: 1.4rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }
    .modal-img {
      width: 100%;
      max-height: 450px;
    }
    .modal-description {
      word-break: break-word;
      padding-top: 1rem;
    }
    button {
      margin-top: 1rem;
      padding: 0.5rem 3.5rem;
    }
  }

  .modal-content {
    background: var(--theme-background-color);
  }

  & .modal-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  & .modal-close-button {
    background-color: transparent;
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 0 !important;
    padding: 0 !important;
    border: none;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      background: var(--theme-accent-color);
      display: block;
      left: 50%;
      right: 50%;
      position: absolute;
      top: 50%;
      transform-origin: center center;
      height: 2px;
      width: 75%;
    }

    &::before {
      transform: translateX(-25%) translateY(-50%) rotate(135deg);
    }
    &::after {
      transform: translateX(-25%) translateY(-50%) rotate(45deg);
    }

    &:hover {
      background-color: transparent;
      filter: brightness(1.1);
      transform: scale(1.1);
    }
  }

  & .modal-close {
    display: none;
  }
}

.mobile-view-modal {
  animation: fadeInUp 0.5s ease;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.broadway {
  .modal-content {
    background: var(--theme-background-color);
    border: 1px solid var(--theme-accent-color);
    box-shadow: 0 0 6px 3px var(--theme-accent-color-opacity-5);
    border-radius: 10px;
    color: var(--theme-font-color);
  }
}
</style>
