<template>
  <div>
    <div class="field paypal-field">
      <b-radio
        v-on:input="onPayPalInput"
        native-value="PAYPAL"
        v-model="state.formValues.bookingDeposit.payment_type"
        v-bind:disabled="state.isSavingPayment || state.isSavingBooking"
      >
        {{ txtPayPal }}
      </b-radio>
    </div>
    <div v-show="showPayPal" class="widget-stripe-paypal">
      <div class="widget-stripe-paypal-element">
        <div ref="paypal" id="paypal-element"><!-- paypal --></div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '../i18n';

export default {
  props: ['state'],
  data: () => {
    return {
      txtPayPal: i18n.gettext('Paypal'),
    };
  },
  mounted: function () {
    this.$nextTick(function () {
      if (this.showPayPal) {
        this.onPayPalInput();
      }
    });
  },
  computed: {
    showPayPal: function () {
      return this.state.formValues.bookingDeposit.payment_type === 'PAYPAL';
    },
  },
  methods: {
    onPayPalInput: function () {
      this.state.paymentError = false;
      this.$refs.paypal.innerHTML = '';
      this.state.paypal
        .Buttons({
          createOrder: this.state.createPayPalOrder,
          onApprove: this.state.processPayPal,
        })
        .render('#paypal-element');
    },
  },
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';

.paypal-field,
.c-card-field {
  color: var(--theme-font-color);
}
</style>
