<template>
  <div
    v-bind:class="[state.fullscreen ? 'widget-fullscreen' : 'widget']"
    v-bind:data-theme="state.widgetStyle"
  >
    <w-fullscreen-details
      v-if="state.fullscreen && !state.disableFullscreenSidebar"
      v-bind:state="state"
    ></w-fullscreen-details>
    <div
      v-bind:class="[
        state.fullscreen ? 'widget-fullscreen-flex' : 'widget-flex',
      ]"
    >
      <div
        v-bind:class="[
          state.fullscreen ? 'widget-wrapper-fullscreen' : 'widget-wrapper',
        ]"
      >
        <form
          class="widget-form-container"
          v-on:submit.prevent="state.handleSubmit"
          v-bind:lang="state.langauge"
        >
          <w-header v-bind:state="state"></w-header>
          <w-content v-bind:state="state"></w-content>
          <w-footer v-bind:state="state"></w-footer>
          <div v-if="state.facilityReachedBookingLimit" class="widget-overlay">
            <p>{{ txtBookingLimit }}</p>
          </div>
        </form>
      </div>
      <div v-if="state.fullscreen" class="widget-flex-footer-legal">
        <a
          class="widget-flex-footer-link"
          :href="state.termsAndConditionsUrl"
          target="_blank"
          >{{ termsAndConditionsText }}</a
        >
        |
        <a
          class="widget-flex-footer-link"
          :href="state.privacyPolicyUrl"
          target="_blank"
          >{{ privacyPolicyText }}</a
        >
        |
        <a
          class="widget-flex-footer-link"
          :href="state.legalNoticeUrl"
          target="_blank"
          >{{ legalNoticeText }}</a
        >
      </div>
    </div>
    <div v-if="state.fullscreen" class="theme-styling-left"></div>
    <div v-if="state.fullscreen" class="theme-styling-top"></div>
    <div v-if="state.fullscreen" class="theme-styling-right"></div>
  </div>
</template>

<script>
import appState from './appState';
import FullscreenDetails from './components/FullscreenDetails';
import Header from './components/Header';
import Content from './components/Content';
import Footer from './components/Footer';
import i18n from './i18n';

export default {
  components: {
    'w-fullscreen-details': FullscreenDetails,
    'w-footer': Footer,
    'w-header': Header,
    'w-content': Content,
  },
  data: () => {
    return {
      state: appState,
      txtBookingLimit: i18n.gettext(
        'For reservations, please call the restaurant.'
      ),
      termsAndConditionsText: i18n.gettext('Terms & Conditions'),
      privacyPolicyText: i18n.gettext('Privacy Policy'),
      legalNoticeText: i18n.gettext('Legal Notice'),
    };
  },
};
</script>

<style lang="scss">
@import './styles/_variables.scss';

.widget {
  height: 100vh;
}

.widget-fullscreen {
  display: flex;
  flex-direction: row;
  height: 100%;
  @media (min-width: $padding-medium) {
    background: var(--theme-background-color);
    height: auto;
    min-height: 100%;
  }
}

.widget[data-theme='glass'],
.widget-fullscreen[data-theme='glass'] {
  background: linear-gradient(
    to right,
    var(--theme-accent-color),
    var(--theme-accent-light-color)
  );
}

.widget-fullscreen[data-theme='glass'] .widget-fullscreen-details,
.widget[data-theme='glass'] {
  background: linear-gradient(
    to right,
    var(--theme-accent-color),
    var(--theme-accent-light-color)
  );
}

.widget,
.widget-fullscreen,
.widget-fullscreen-details,
.widget-fullscreen-flex {
  @at-root #{$broadway} & {
    background: var(--theme-background-color);
  }
}

.widget-flex {
  height: 100vh;

  @at-root #{$broadway} &,
    #{$glass} & {
    z-index: 1;
  }
}

.widget-fullscreen-flex {
  display: flex;
  flex-direction: column;
  @media (min-width: $padding-medium) {
    padding-top: 20vh;
  }

  @at-root #{$broadway} &,
    #{$glass} & {
    z-index: 1;
  }
}

@media (max-width: $padding-medium) {
  .widget-fullscreen-flex,
  .widget-flex {
    @at-root #{$glass} & {
      background: linear-gradient(
        to bottom,
        var(--theme-accent-light-color) 0%,
        var(--theme-background-color) 100%
      );
      backdrop-filter: blur(3px);
    }
  }
}

.widget-flex-footer {
  align-items: flex-end;
  flex: 1 0 auto;
  display: none;
  margin: 0 auto;
  padding-top: 25px;
  @media (min-width: $padding-medium) {
    display: flex;
  }
}

.widget-flex-footer-legal {
  padding: 20px 0px;
  text-align: center;

  @media (max-width: $padding-medium) {
    background-color: var(--theme-background-color);
  }

  & a {
    opacity: 0.8;
  }

  @at-root #{$broadway} & {
    opacity: 1;
  }
  @at-root #{$glass} & {
    & a {
      color: var(--theme-font-color);
    }
    @media screen and (max-width: $padding-medium) {
      background: var(--theme-background-color);
    }
  }
}

.widget-flex-footer-link {
  color: inherit;

  &:hover {
    color: var(--theme-accent-color);
  }
}

.widget-wrapper-fullscreen {
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.1);
  background-color: var(--theme-background-color);
  height: 100%;
  width: 100%;
  margin: 0;
  border-radius: var(--border-radius);

  @media (min-width: $padding-medium) {
    padding: 15px;
    max-width: 315px;
    min-width: 315px;
    max-height: $maxWidgetHeight;
    margin: 0 auto;
  }

  @media (min-width: $padding-medium) {
    max-width: $maxWidgetWidth;
    min-width: $maxWidgetWidth;
  }
  @at-root #{$broadway} & {
    background: var(--theme-background-color);
    border: 1px solid var(--theme-accent-color);
    overflow: auto;
    box-shadow: 0 0 15px 9px var(--theme-accent-color-opacity-5);
  }

  @at-root #{$glass} & {
    background: linear-gradient(
      to bottom,
      var(--theme-accent-light-color) 0%,
      var(--theme-background-color) 100%
    );
  }
  @media (max-width: $padding-medium) {
    .widget-form-container {
      background: var(--theme-background-color);
    }
  }
}

.widget-wrapper {
  height: 100vh;
  border-radius: var(--border-radius);
  @at-root #{$broadway} & {
    background: var(--theme-background-color);
    border: 1px solid var(--theme-accent-color);
    overflow: auto;
    box-shadow: 0 0 15px 9px var(--theme-accent-color-opacity-5);
  }
}

.widget-overlay {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--theme-background-color-opacity-6);

  p {
    margin-top: 45vh;
    background-color: var(--theme-background-color);
    text-align: center;
    padding: 5px;
    font-size: 14px;
    font-weight: 500;
  }
}

.widget-form-container {
  display: flex;
  position: relative;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  background: var(--theme-background-color);

  @at-root #{$broadway} &,
    #{$glass} & {
    background: transparent;
  }
}

.widget-form-container:invalid .custom-fields-next-button {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.theme-styling-right {
  @at-root #{$broadway} & {
    position: absolute;
    right: 4%;
    bottom: 0;
    width: 14%;
    height: 100%;
    background-image: radial-gradient(
      circle at 9px 7px,
      var(--theme-accent-color) 6px,
      transparent 4px
    );
    background-size: 37px 30px;
  }

  @at-root #{$glass} & {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 60%;
    width: 30%;
    aspect-ratio: 1;
    border-radius: 0 0 100% 0;
    background: var(--theme-accent-color);
    transform: translate(10%, 30%) rotate(180deg);
    filter: blur(6.5px);
    mix-blend-mode: multiply;
  }
}

.theme-styling-top {
  @at-root #{$broadway} & {
    position: absolute;
    left: 3.5%;
    top: 11%;
    width: 40%;
    height: 26%;
    background-image: radial-gradient(
      circle at 9px 7px,
      var(--theme-accent-color) 6px,
      transparent 4px
    );
    background-size: 38px 38px;
  }

  @at-root #{$glass} & {
    position: absolute;
    left: 46%;
    top: 14%;
    width: 10%;
    aspect-ratio: 1;
    border-radius: 50%;
    background: linear-gradient(
      180deg,
      var(--theme-accent-color) 40.4%,
      var(--theme-accent-light-color) 89.79%
    );
    filter: blur(34px);
    mix-blend-mode: multiply;
    animation: pulseCircle 20s infinite alternate;

    @keyframes pulseCircle {
      0% {
        width: 10%;
      }
      25% {
        width: 13%;
      }
      50% {
        width: 10%;
      }
      75% {
        width: 13%;
      }
      100% {
        width: 10%;
      }
    }
  }
}

.theme-styling-left {
  @at-root #{$broadway} & {
    position: absolute;
    left: 11%;
    bottom: 20%;
    width: 40%;
    height: 26%;
    background-image: radial-gradient(
      circle at 9px 7px,
      var(--theme-accent-color) 6px,
      transparent 4px
    );
    background-size: 36px 30px;
  }

  @at-root #{$glass} & {
    position: absolute;
    left: 7%;
    top: 40%;
    width: 25%;
    aspect-ratio: 1;
    border-radius: 50%;
    background: linear-gradient(
      180deg,
      var(--theme-accent-color) 40.4%,
      var(--theme-accent-light-color) 89.79%
    );
    mix-blend-mode: multiply;
    filter: blur(1px);
  }
}
</style>
