<template>
  <div class="widget-additional-phone-input" aria-hidden="true">
    <label for="additional-phone">{{ txtHoneypotLabel }}</label>
    <w-input
      :required="state.isHoneypotFieldRequired"
      v-bind:placeholder="txtHoneypotPlaceholder"
      v-model="state.formValues.honeypotField"
      name="additional-phone"
      type="text"
      tabindex="-1"
      autocomplete="off"
    />
  </div>
</template>

<script>
import i18n from '../i18n';
import InputField from './common/FormInputField';

export default {
  props: ['state'],
  components: {
    'w-input': InputField,
  },
  mounted() {
    setTimeout(() => {
      this.state.isHoneypotFieldRequired = false;
    }, 1500);
    if (typeof this.state.formValues.honeypotRenderTime === 'undefined') {
      this.state.formValues.honeypotRenderTime = Date.now();
    }
  },
  computed: {
    txtHoneypotLabel: function () {
      return i18n.gettext('Do not fill out the field below!');
    },
    txtHoneypotPlaceholder: function () {
      return i18n.gettext('Second phone');
    },
  },
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';

.widget-additional-phone-input {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
</style>
