<template>
  <w-picker
    class="guest-picker"
    :label="formattedNum"
    @click="handleClick"
  ></w-picker>
</template>

<script>
import i18n from '../i18n';
import Picker from './common/Picker.vue';
import ListSelect from './common/ListSelect.vue';

export default {
  components: {
    'w-picker': Picker,
  },
  props: ['state'],
  computed: {
    num: function () {
      return this.state.formValues.num;
    },
    formattedNum: function () {
      return i18n.sprintf(
        i18n.ngettext('1 Guest', '%1d Guests', this.num),
        this.num
      );
    },
    numOptions: function () {
      return this.state.numOptions;
    },
    guestSelectOptions: function () {
      return this.state.numOptions.map((num) => ({
        id: num,
        num,
        title: i18n.sprintf(i18n.ngettext('1 Guest', '%1d Guests', num), num),
      }));
    },
  },
  methods: {
    handleClick() {
      const guestModalRef = this.$modal.open({
        parent: this,
        component: ListSelect,
        props: {
          options: this.guestSelectOptions,
          state: this.state,
          showAdditionalContent: true,
        },
        events: {
          select: this.handleGuestSelect,
        },
        customClass: `guestpicker-modal ${this.state.widgetStyle}`,
        canCancel: ['escape', 'outside'],
      });
      this.guestModalRef = guestModalRef;
    },
    handleGuestSelect: function ({ num }) {
      this.state.formValues.num = num;
      this.state.getAvailabilities();
      this.state.showGuestSelect = false;
      this.guestModalRef.close();
    },
  },
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';

.guest-picker {
  border-color: var(--theme-font-color-opacity-5);

  @at-root #{$broadway} & {
    border: none;
  }

  @at-root #{$glass} & {
    border: none;

    .icon-container {
      color: var(--theme-background-color);
      &:not(.icon-disabled):active {
        color: var(--theme-accent-color);
      }
    }
  }
}

.guestpicker-modal {
  .modal-background {
    opacity: 0.2;
  }
  .modal-content {
    background: var(--theme-background-color);
    color: var(--theme-font-color);
    max-width: 20rem !important;
    max-height: 25rem;
    border-radius: 10px;
  }
}
.broadway,
.glass {
  .modal-content {
    color: var(--theme-font-color);
    background: var(--theme-background-color) !important;
  }
}
</style>
