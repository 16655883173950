const configureStripe = ({
  stripePublishKey,
  bookingDepositEnabledByAddon
}) => {
  if (bookingDepositEnabledByAddon && stripePublishKey) {
    if (window.Stripe) {
      return window.Stripe(stripePublishKey)
    }
  }
  return null
}

export default configureStripe
