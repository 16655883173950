import root from './root';
import cs from './cs';
import da from './da';
import de from './de';
import es from './es';
import fi from './fi';
import fr from './fr';
import he from './he';
import hr from './hr';
import it from './it';
import lt from './lt';
import lv from './lv';
import nl from './nl';
import pl from './pl';
import pt from './pt';
import ru from './ru';
import sv from './sv';
import tr from './tr';
import zh from './zh';

const languageMessages = {
  root,
  cs,
  da,
  de,
  es,
  fi,
  fr,
  he,
  hr,
  it,
  lt,
  lv,
  nl,
  pl,
  pt,
  ru,
  sv,
  tr,
  zh,
};

export default languageMessages;
