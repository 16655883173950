<template>
  <div style="overflow: auto; height: 100%">
    <div class="widget-booking-content" v-if="state.facilityHours">
      <div class="widget-booking-body">
        <w-availabilities v-bind:state="state"></w-availabilities>
      </div>
    </div>
    <div v-else>
      <b-message type="is-danger">
        {{ txtFacilityHourError }}
      </b-message>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';
import i18n from '../i18n';

import Availabilities from './Availabilities';

export default {
  components: {
    'w-availabilities': Availabilities,
  },
  props: ['state'],
  data: () => {
    return {
      nextMonthEvents: [],
    };
  },
  computed: {
    txtFacilityHourError: function () {
      return i18n.gettext(
        'There are no available booking times for your facility.'
      );
    },
  },
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';

.has-event .events .event.is-link {
  display: none;
}

// the css :has() selector is currently supported in pretty much all modern browsers (less support for mobile), but we provide a fallback which overlaps the child div with the parent to apply an overlay with an opacity filter to achieve a similar outcome.
.has-event:not(.is-selected) {
  // prettier-ignore
  @supports (selector(:has(.is-link))) {
    &:has(.is-link) {
      filter: opacity(0.5);
    }
  }
  // prettier-ignore
  @supports (not (selector(:has(.is-link)))) {
    .event.is-link:first-child {
      display: block !important;
      position: absolute;
      top: 50%;
      left: 30%;
      z-index: 0;
      filter: opacity(0.5);
      background: var(--theme-background-color) !important;
      width: 40% !important;
      height: 40% !important;
      border-radius: 0 !important;

      @at-root #{$glass} & {
        filter: none;
        background: none !important;
        top: 45%;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          background: var(--theme-background-color-opacity-6);
          filter: opacity(0.9) blur(6px);
          border-radius: inherit;
        }
      }

      &:hover {
        filter: opacity(0.5) brightness(0.9);
      }
    }
  }
}

.outside-month-off-day.dots.is-selectable {
  filter: opacity(0.5);
}

.event.is-warning,
.event.is-danger {
  position: absolute;
  bottom: 0;
}

.widget-booking-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: hidden;
}

.events .is-warning,
.outside-month-event::after {
  background-color: var(--theme-accent-color) !important;
}

.widget-booking-body {
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;

  @at-root #{$glass} & {
    background: transparent;
  }
}

.widget-dropdown-error {
  border-color: $danger;
  color: $danger;
}

.widget-dropdown-select {
  position: relative;
  width: 110px;
  cursor: pointer;
  padding-right: 30px;
  border-bottom: 1px solid $grey-lighter;
  white-space: nowrap;

  &.widget-dropdown-error {
    border-color: $danger;
    color: $danger;
  }

  &.widget-dropdown-error:hover {
    border-color: $danger-hover;
    color: $danger-hover;

    &::after {
      border-color: $danger-hover;
    }
  }

  &::after {
    border: 2px solid currentColor;
    border-right: 0;
    border-top: 0;
    content: ' ';
    display: block;
    height: 10px;
    margin-top: -7px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 5px;
    transform: rotate(-45deg);
    transform-origin: center;
    width: 10px;
  }

  &:hover {
    border-color: var(--theme-accent-color);
    &::after {
      border-color: var(--theme-accent-color);
    }
  }
}

.icon-container {
  &:not(.date-disabled)svg {
    cursor: pointer;
    &:hover {
      transform: scale(1.04);
    }
  }
}

.outside-month-event {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    display: flex;
    justify-content: center;
    background: var(--theme-accent-color);
    height: 0.35em;
    width: 0.35em;
    border-radius: 50%;
    bottom: 17%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.datepicker-cell.is-selectable:not(.is-selected):hover,
.widget-booking-content-availability:not(.selected-availability):hover {
  @at-root #{$broadway} & {
    position: relative;
    z-index: 1;
    background-color: transparent !important;
    &::before {
      content: '';
      border: 1px solid var(--theme-accent-color);
      border-radius: 20px;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      top: 0;
      left: 0;
      pointer-events: none;
    }
  }

  @at-root #{$glass} & {
    position: relative;
    z-index: 1;
    & .availability-text {
      color: var(--theme-font-color);
    }
    & .events .is-link::after {
      background: var(--theme-accent-color-opacity-5);
    }
    &::before {
      content: '';
      background: var(--theme-accent-light-color);
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      top: 0;
      left: 0;
      filter: blur(4px);
      border-radius: 10px;
      pointer-events: none;
    }
  }
}

.widget-booking-content-availability.disabled-availability:hover {
  @at-root #{$broadway} &,
    #{$glass} & {
    &::before {
      background: transparent;
    }
  }
}
</style>
