<template>
  <w-external :state="state" :heading="txtHeading" :cancelled="true">
    <div style="text-align: center;">{{ txtCantCome }}</div>
  </w-external>
</template>

<script>
import i18n from '../i18n';
import External from './External';

export default {
  components: {
    'w-external': External,
  },
  props: ['state'],
  data: function () {
    return {
      txtCantCome: i18n.gettext(
        "Too bad you can't come over! See you next time!"
      ),
    };
  },
  computed: {
    isBooking: function () {
      return this.state.booking.ref_num !== null;
    },
    txtHeading: function () {
      if (this.isBooking) {
        return i18n.gettext('Your booking has been cancelled.');
      } else {
        return i18n.gettext(
          'Your booking request has been removed. You are no longer on our waitlist.'
        );
      }
    },
  },
};
</script>
