<template>
  <div class="widget-logo-container" v-on:click="handleLogoClick">
    <!-- v-if on svg does not work in parcel production built -->
    <template v-if="isOrderbirdFacility">
      <svg
        class="widget-logo-orderbird"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 12 110 40"
      >
        <path
          fill="#00A3E0"
          d="M28,21.3c-7.1-2.9-8.7,1.9-9.2,2.8c-3.9-6-9.4-7-9.4-7c0.7,1.1,1.2,1.8,2.2,4.6
          C9.1,21.1,6,20.9,6,20.9s3.9,2.5,6.3,7.9c-4.5,2.1-8.1,1.9-12.4,2c1,0.5,8.7,4.9,17.1,1.8c3.3-1.2,5.8-3.5,8.1-6.3
          c1.4-1.9,2.3-3.5,6.5-5.2c0.4-0.1,0.4-0.6-0.2-0.5C31.5,20.6,29,21,28,21.3 M8.5,22c1.2,0.1,2.7,0.4,3.3,0.5
          c0.6,1.8,1.1,4.3,1.2,6.1C12.1,26.2,10.1,23.5,8.5,22"
        />
        <path
          fill="#01426A"
          d="M35.9,38.7c-0.9,0-1.6-0.1-2.3-0.4c-0.7-0.3-1.2-0.7-1.7-1.2c-0.4-0.5-0.8-1.1-1.1-1.8
          c-0.2-0.7-0.3-1.5-0.3-2.3s0.1-1.6,0.3-2.3c0.2-0.7,0.6-1.3,1.1-1.8c0.5-0.5,1-0.9,1.7-1.2c0.7-0.3,1.4-0.4,2.3-0.4
          c0.8,0,1.6,0.1,2.3,0.4c0.7,0.3,1.2,0.7,1.7,1.2c0.4,0.5,0.8,1.1,1.1,1.8c0.2,0.7,0.3,1.4,0.3,2.3c0,0.9-0.1,1.6-0.3,2.3
          c-0.2,0.7-0.6,1.3-1.1,1.8c-0.4,0.5-1,0.9-1.7,1.2C37.5,38.6,36.7,38.7,35.9,38.7 M35.9,36.8c0.5,0,1-0.1,1.3-0.3s0.7-0.5,1-0.9
          s0.4-0.8,0.5-1.2c0.1-0.4,0.2-0.9,0.2-1.4c0-0.5,0-0.9-0.2-1.4c-0.1-0.5-0.3-0.9-0.5-1.2s-0.6-0.7-1-0.9
          c-0.4-0.2-0.8-0.3-1.3-0.3c-0.5,0-1,0.1-1.3,0.3c-0.4,0.2-0.7,0.5-1,0.9s-0.4,0.8-0.5,1.2c-0.1,0.4-0.2,0.9-0.2,1.4
          c0,0.5,0,0.9,0.2,1.4c0.1,0.5,0.3,0.9,0.5,1.2s0.6,0.7,1,0.9C34.9,36.7,35.3,36.8,35.9,36.8"
        />
        <path
          fill="#01426A"
          d="M46.8,29.9c-0.4,0.2-0.6,0.4-0.9,0.7c-0.3,0.3-0.5,0.7-0.6,1.1c-0.1,0.5-0.2,1-0.2,1.6v5h-2.4v-11h2.2v2
          c0,0,1-2,4-2v2.2C48.9,29.6,47.6,29.5,46.8,29.9"
        />
        <path
          fill="#01426A"
          d="M59.7,32.8L59.7,32.8v-9.3h-2.2v4.7c-0.3-0.2-0.6-0.3-0.9-0.5c-0.7-0.3-1.4-0.4-2.3-0.4
          c-0.8,0-1.6,0.1-2.3,0.4s-1.2,0.7-1.7,1.2c-0.4,0.5-0.8,1.1-1.1,1.8c-0.2,0.7-0.3,1.4-0.3,2.3s0.1,1.6,0.3,2.3
          c0.2,0.7,0.6,1.3,1.1,1.8c0.5,0.5,1,0.9,1.7,1.2c0.7,0.3,1.4,0.4,2.3,0.4c0.8,0,1.6-0.1,2.3-0.4c0.7-0.3,1.2-0.7,1.7-1.2
          c0.4-0.5,0.8-1.1,1.1-1.8c0.2-0.7,0.3-1.5,0.3-2.3C59.7,32.9,59.7,32.9,59.7,32.8 M57.2,34.4c-0.1,0.5-0.3,0.9-0.5,1.2
          c-0.2,0.3-0.6,0.7-1,0.9c-0.4,0.2-0.8,0.3-1.3,0.3c-0.5,0-1-0.1-1.3-0.3s-0.7-0.5-1-0.9c-0.2-0.3-0.4-0.8-0.5-1.2
          c-0.1-0.5-0.2-0.9-0.2-1.4c0-0.5,0-0.9,0.2-1.4c0.1-0.5,0.3-0.9,0.5-1.2c0.2-0.3,0.6-0.7,1-0.9c0.4-0.2,0.8-0.3,1.3-0.3
          c0.5,0,1,0.1,1.3,0.3s0.7,0.5,1,0.9c0.2,0.3,0.4,0.8,0.5,1.2c0.1,0.5,0.2,0.9,0.2,1.4C57.4,33.5,57.3,34,57.2,34.4"
        />
        <path
          fill="#01426A"
          d="M63.4,33.6c0,0.4,0,0.8,0.2,1.2c0.1,0.4,0.3,0.7,0.5,1c0.2,0.3,0.5,0.5,0.9,0.7c0.3,0.1,0.8,0.3,1.3,0.3
          c0.7,0,1.2-0.1,1.7-0.4c0.4-0.3,0.7-0.7,1-1.3h2.2c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,0.9-1.1,1.2c-0.4,0.3-0.9,0.5-1.4,0.7
          c-0.5,0.1-1.1,0.2-1.7,0.2c-0.8,0-1.6-0.1-2.2-0.4c-0.6-0.3-1.2-0.7-1.6-1.2c-0.4-0.5-0.8-1.1-1-1.8c-0.2-0.7-0.3-1.5-0.3-2.3
          c0-0.8,0.1-1.5,0.3-2.2c0.2-0.7,0.6-1.3,1-1.8c0.4-0.5,1-0.9,1.6-1.2c0.6-0.3,1.3-0.5,2.2-0.5c0.8,0,1.6,0.2,2.3,0.5
          s1.2,0.8,1.7,1.4c0.4,0.6,0.8,1.2,1,2c0.2,0.8,0.2,1.5,0.1,2.4L63.4,33.6L63.4,33.6L63.4,33.6L63.4,33.6z M68.9,32.1
          c0-0.4-0.1-0.7-0.2-1.1s-0.3-0.7-0.5-0.9s-0.5-0.5-0.8-0.6c-0.3-0.1-0.7-0.2-1.1-0.2c-0.4,0-0.8,0.1-1.1,0.2
          c-0.3,0.1-0.6,0.3-0.9,0.6c-0.2,0.2-0.4,0.6-0.6,0.9c-0.1,0.3-0.2,0.7-0.2,1.1L68.9,32.1L68.9,32.1z"
        />
        <path
          fill="#01426A"
          d="M76.7,30c-0.4,0.2-0.6,0.4-0.9,0.7c-0.3,0.3-0.5,0.7-0.6,1.2S75,32.9,75,33.5v5.2h-2.4V27.4h2.2v1.7
          c0,0,1-1.7,4-1.7v2.3C78.9,29.7,77.6,29.6,76.7,30"
        />
        <path
          fill="#01426A"
          d="M90.1,31c-0.2-0.7-0.5-1.3-1-1.8c-0.4-0.5-0.9-1-1.5-1.2c-0.6-0.3-1.3-0.4-2.1-0.4S84,27.7,83.4,28
          c-0.6,0.3-1.1,0.7-1.5,1.2c-0.2,0.2-0.4,0.5-0.5,0.8v-6.5h-0.7v9.7l0,0l0,0c0,0.8,0.1,1.5,0.3,2.2c0.2,0.7,0.5,1.3,1,1.8
          s0.9,0.9,1.5,1.2c0.6,0.3,1.3,0.4,2.1,0.4s1.5-0.1,2.1-0.4c0.6-0.3,1.1-0.7,1.5-1.2c0.4-0.5,0.7-1.2,1-1.8
          c0.2-0.7,0.3-1.4,0.3-2.2C90.4,32.4,90.3,31.7,90.1,31L90.1,31z M89.4,35.1c-0.2,0.6-0.4,1.2-0.8,1.6c-0.3,0.5-0.8,0.8-1.3,1.1
          c-0.5,0.3-1.1,0.4-1.7,0.4c-0.6,0-1.2-0.1-1.7-0.4c-0.5-0.3-1-0.6-1.3-1.1s-0.6-1-0.8-1.6c-0.2-0.6-0.3-1.2-0.3-1.9
          s0.1-1.3,0.3-1.9c0.2-0.6,0.4-1.2,0.8-1.6c0.3-0.4,0.8-0.8,1.3-1.1c0.5-0.3,1.1-0.4,1.7-0.4c0.6,0,1.2,0.1,1.7,0.4
          c0.5,0.3,1,0.6,1.3,1.1c0.3,0.5,0.6,1,0.8,1.6c0.2,0.6,0.3,1.2,0.3,1.9C89.6,33.9,89.5,34.5,89.4,35.1L89.4,35.1z"
        />
        <polygon
          fill="#01426A"
          points="92.1,27.5 92.9,27.5 92.9,38.9 92.1,38.9 			"
        />
        <path
          fill="#01426A"
          d="M96.2,30.1L96.2,30.1c0.1-0.4,0.3-0.7,0.6-1c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.8-0.4,1.2-0.5
          c0.4-0.1,0.9-0.2,1.4-0.2v0.8c-0.4,0-0.9,0-1.4,0.1c-0.5,0.1-1,0.5-1.5,1c-0.2,0.2-0.3,0.4-0.5,0.6c-0.1,0.2-0.3,0.4-0.3,0.7
          c-0.1,0.2-0.1,0.5-0.2,0.8c0,0.3-0.1,0.7-0.1,1.1v6.2h-0.8V27.6h0.8L96.2,30.1L96.2,30.1z"
        />
        <path
          fill="#01426A"
          d="M110,23.5h-0.8V30c-0.1-0.3-0.3-0.6-0.5-0.8c-0.4-0.5-0.9-1-1.5-1.2c-0.6-0.3-1.3-0.4-2.1-0.4
          s-1.5,0.1-2.1,0.4c-0.6,0.3-1.1,0.7-1.5,1.2c-0.4,0.5-0.7,1.2-1,1.8c-0.2,0.7-0.3,1.4-0.3,2.2s0.1,1.5,0.3,2.2
          c0.2,0.7,0.5,1.3,1,1.8c0.4,0.5,0.9,0.9,1.5,1.2c0.6,0.3,1.3,0.4,2.1,0.4s1.5-0.1,2.1-0.4c0.6-0.3,1.1-0.7,1.5-1.2
          c0.4-0.5,0.7-1.2,1-1.8c0.2-0.7,0.3-1.4,0.3-2.2l0,0l0,0L110,23.5L110,23.5z M108.9,35.1c-0.2,0.6-0.4,1.2-0.8,1.6
          c-0.3,0.5-0.8,0.8-1.3,1.1c-0.5,0.3-1.1,0.4-1.7,0.4c-0.7,0-1.2-0.1-1.7-0.4c-0.5-0.3-1-0.6-1.3-1.1c-0.3-0.5-0.6-1-0.8-1.6
          c-0.2-0.6-0.3-1.2-0.3-1.9s0.1-1.2,0.3-1.9s0.4-1.2,0.8-1.6c0.3-0.5,0.8-0.8,1.3-1.1c0.5-0.3,1.1-0.4,1.7-0.4
          c0.7,0,1.2,0.1,1.7,0.4c0.5,0.3,1,0.6,1.3,1.1c0.3,0.5,0.6,1,0.8,1.6c0.2,0.6,0.3,1.2,0.3,1.9S109.1,34.4,108.9,35.1L108.9,35.1z
          "
        />
      </svg>
    </template>
    <template v-else>
      <svg
        class="widget-logo"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 817.95 283.81"
      >
        <path
          fill-rule="evenodd"
          fill="#E52D50"
          d="M119.63 92.55C93.48 61.3 46.49 57.57 6.67 71.09l17.18 75.22C43.04 114.08 85.41 98.08 119.63 92.55z"
        />
        <path
          fill-rule="evenodd"
          fill="#00A7C4"
          d="M170.08 128.58c21.12-35.26 10.28-82.25-14.77-116.21L90.31 52.46C126.95 60.95 154.69 97.66 170.08 128.58z"
        />
        <path
          fill-rule="evenodd"
          fill="#4AB6A0"
          d="M153.38 185.23c43.27 6.69 76.66-10.9 102.33-49.34l-59.48-52.65C198.92 123.82 181.67 156.73 153.38 185.23z"
        />
        <path
          fill-rule="evenodd"
          fill="#4F4494"
          d="M93 186.26c3.1 40.65 38.64 72.39 78.53 84.67l29.88-71.7C166.83 213.66 123.43 201.93 93 186.26z"
        />
        <path
          fill-rule="evenodd"
          fill="#F8C04F"
          d="M73.47 127.27c-38.3 15.73-55.69 60.65-55.34 103.16l75.15 6.49C68.16 207.59 69.47 161.28 73.47 127.27z"
        />
        <path
          fill-rule="evenodd"
          fill="currentColor"
          d="M304.86 124.85l-5.95 57.8c0 1.51 0.75 2.85 2.52 3.85 1.67 1.09 4.19 1.59 7.54 1.59h4.86c7.03 0 10.72-1.93 11.14-5.61l5.53-56.37c4.52-5.95 9.63-8.96 15.33-8.96 1.68 0 3.18 0.25 4.61 0.76 1.34 0.59 2.26 0.83 2.6 0.83 1.26 0 2.77-2.01 4.52-6.03 1.68-3.94 2.52-7.46 2.52-10.39 0-5.44-2.68-8.12-8.21-8.12 -7.79 0-14.66 4.44-20.61 13.4 -0.25-2.85-0.42-4.86-0.58-6.03 -0.25-1.25-0.75-2.43-1.59-3.68 -0.75-1.17-1.93-1.76-3.43-1.76 -5.86 0-10.81 0.59-14.57 1.76 -3.85 1.25-5.78 2.68-5.78 4.61l0.34 9.8C305.62 116.14 305.28 120.33 304.86 124.85z"
        />
        <path
          fill-rule="evenodd"
          fill="currentColor"
          d="M420.45 123.09c0 3.1-1.68 5.78-4.94 8.04 -3.35 2.35-8.71 3.44-16.16 3.44h-8.46c2.93-13.15 9.21-19.76 19.01-19.76C416.93 114.8 420.45 117.64 420.45 123.09zM389.62 150.73c5.78 0.84 11.81 1.25 18.26 1.25 9.97 0 18.76-2.68 26.38-8.04 7.62-5.28 11.39-13.4 11.39-24.21 0-5.53-2.09-10.8-6.28-16 -2.26-2.85-5.78-5.11-10.55-6.87 -4.86-1.76-10.56-2.68-17.17-2.68 -14.24 0-25.88 4.61-34.84 13.82 -9.05 9.13-13.57 23.2-13.57 42.38 0 12.23 3.43 21.69 10.3 28.56 6.78 6.78 17.25 10.22 31.24 10.22 6.36 0 12.81-1.01 19.1-2.93 6.28-1.93 10.14-5.03 11.48-9.38 0.25-0.84 0.5-2.93 0.84-6.12 0.34-3.18 0.59-5.78 0.59-7.54 0-1.59-0.5-2.43-1.42-2.43 -0.34 0-1.84 0.67-4.52 1.93 -2.6 1.34-6.03 2.6-10.22 3.85 -4.27 1.34-8.8 1.93-13.74 1.93 -4.94 0-8.96-1.17-12.06-3.69C391.72 162.21 389.96 157.6 389.62 150.73z"
        />
        <path
          fill-rule="evenodd"
          fill="currentColor"
          d="M506.22 116.39c2.93 1.26 4.52 1.76 4.78 1.76 1.59 0 3.52-1.68 5.78-5.19 2.34-3.43 3.52-6.11 3.52-8.04 0-3.68-2.68-6.45-8.13-8.12 -5.36-1.68-11.22-2.6-17.59-2.6 -10.3 0-18.68 2.85-25.13 8.46 -6.53 5.61-9.71 13.65-9.71 24.12 0 4.86 1.09 8.88 3.26 12.07 2.18 3.26 4.78 5.61 7.88 7.03 3.01 1.51 6.03 2.77 9.13 3.85 3.1 1.09 5.61 2.43 7.87 3.94 2.09 1.59 3.18 3.6 3.18 6.03 0 5.95-3.85 8.8-11.56 8.8 -5.36 0-10.81-1.43-16.25-4.36 -1.84-1.09-2.85-1.59-3.1-1.59 -2.6 0-3.93 5.28-3.93 15.83 0 4.44 4.1 7.46 12.31 9.38 4.27 0.92 8.63 1.42 13.07 1.42 10.72 0 19.52-3.01 26.22-9.13 6.62-6.11 9.97-14.58 9.97-25.46 0-5.95-1.67-10.81-4.94-14.58 -3.27-3.77-6.87-6.28-10.72-7.45 -3.85-1.26-7.37-2.76-10.72-4.52 -3.27-1.76-4.86-3.85-4.86-6.37 0-4.69 3.27-7.03 9.97-7.03C500.02 114.63 503.2 115.22 506.22 116.39z"
        />
        <path
          fill-rule="evenodd"
          fill="currentColor"
          d="M537.12 124.85l-5.95 57.8c0 1.51 0.84 2.85 2.52 3.85 1.67 1.09 4.19 1.59 7.54 1.59h4.94c7.04 0 10.72-1.93 11.06-5.61l5.62-56.54c4.52-6.87 9.8-10.22 15.75-10.22 5.95 0 8.88 2.93 8.88 8.8 0 1.17-2.01 20.52-5.86 58.13 0 1.51 0.84 2.85 2.6 3.85 1.68 1.09 4.19 1.59 7.46 1.59h4.94c7.03 0 10.72-1.93 11.05-5.61l5.45-56.71c4.61-6.7 9.8-10.05 15.75-10.05 5.95 0 8.88 2.93 8.88 8.8l-4.02 42.21c0 14.99 7.04 22.45 21.28 22.45 3.52 0 6.78-0.84 9.97-2.34 3.18-1.59 5.03-3.85 5.53-6.87 0.76-4.77 1.17-8.12 1.17-9.88 0-1.76-0.5-2.6-1.34-2.6l-1.93 0.5c-1.26 0.34-2.26 0.5-3.18 0.5 -3.52 0-5.28-1.93-5.28-5.78l4.36-46.15c0-14.82-7.7-22.36-23.2-22.36 -5.28 0-10.55 1.42-15.83 4.1 -5.28 2.68-9.38 5.87-12.31 9.47 -2.6-9.05-9.97-13.57-22.2-13.57 -5.02 0-10.05 1.34-15.07 4.02 -5.03 2.6-9.05 5.78-12.15 9.55 -0.08-2.51-0.25-4.61-0.5-6.03 -0.67-3.68-2.43-5.61-5.11-5.61 -5.86 0-10.8 0.59-14.57 1.76 -3.85 1.25-5.78 2.68-5.78 4.61l0.34 9.8C537.88 116.14 537.54 120.33 537.12 124.85z"
        />
        <path
          fill-rule="evenodd"
          fill="currentColor"
          d="M685.71 100.89l-6.62 65.83c0 14.99 7.03 22.45 21.28 22.45 3.43 0 6.79-0.84 9.97-2.34 3.18-1.59 5.03-3.85 5.53-6.87 0.75-4.77 1.17-8.12 1.17-9.88 0-1.76-0.5-2.6-1.42-2.6l-1.93 0.5c-1.25 0.34-2.26 0.5-3.1 0.5 -3.52 0-5.28-1.93-5.28-5.78 0-1.09 2.09-21.86 6.45-61.98 0-1.5-0.92-2.76-2.6-3.85 -1.67-1.09-4.19-1.59-7.37-1.59h-4.94C689.81 95.28 686.05 97.13 685.71 100.89zM714.36 74.85l1.59-14.49c0-1.51-0.84-2.76-2.51-3.85 -1.76-1.01-4.19-1.59-7.54-1.59h-4.86c-7.04 0-10.81 1.93-11.06 5.69l-1.67 14.41c0 1.51 0.83 2.85 2.6 3.93 1.67 1.01 4.19 1.51 7.54 1.51h4.86C710.34 80.46 714.02 78.61 714.36 74.85z"
        />
        <path
          fill-rule="evenodd"
          fill="currentColor"
          d="M751.88 149.31c0-11.14 1.68-19.68 5.19-25.63 3.35-5.95 8.21-8.88 14.49-8.88 9.13 0 13.74 6.53 13.74 19.35 0 11.06-1.76 19.6-5.28 25.55 -3.43 5.95-8.21 8.8-14.32 8.8C756.49 168.49 751.88 162.12 751.88 149.31zM735.13 179.29c6.45 6.62 16 9.89 28.48 9.89 14.99 0 26.81-4.94 35.35-14.74 8.54-9.8 12.9-23.62 12.9-41.46 0-12.65-3.27-22.28-9.72-28.9 -6.45-6.53-16-9.88-28.48-9.88 -8.8 0-16.5 1.59-23.03 4.69 -6.45 3.1-11.48 7.37-14.99 12.81 -6.78 10.56-10.13 23.54-10.13 38.87C725.5 163.13 728.68 172.68 735.13 179.29z"
        />
      </svg>
    </template>
  </div>
</template>

<script>
import appState from '../../appState';

export default {
  computed: {
    isOrderbirdFacility() {
      return appState.isOrderbirdFacility;
    },
  },
  methods: {
    handleLogoClick: function (e) {
      const url = this.isOrderbirdFacility
        ? 'https://orderbird.com'
        : 'https://resmio.com';
      window.open(url);
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/_variables.scss';
.widget-logo {
  height: 20px;
  width: 90px;
}
.widget-logo-container {
  display: flex;
  cursor: pointer;
}
.widget-logo-orderbird {
  height: 40px;
  width: 110px;
}
</style>
