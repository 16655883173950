<template>
  <b-field class="widget-form-field">
    <b-input
      class="widget-field-input"
      v-bind="$attrs"
      v-bind:placeholder="placeholder"
      v-bind:required="required"
      v-bind:value="value"
      v-on="$listeners"
    />
  </b-field>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: [Number, String],
    placeholder: String,
    required: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/_variables.scss';

.widget-form-field {
  position: relative;

  .widget-field-input {
    width: 100%;

    input::placeholder {
      color: var(--theme-font-color) !important;
      filter: opacity(0.5);
    }
    input:focus {
      &::placeholder {
        filter: opacity(1);
      }
    }
  }
}

.widget-form-field,
.widget-field-input {
  @at-root #{$broadway} & {
    border: 1px solid var(--theme-accent-color);
    border-radius: 20px;
  }
}

.input {
  border-bottom: 1px solid var(--theme-accent-color);
}
</style>
