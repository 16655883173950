<template>
  <div class="widget-booking-body" ref="datepickerContainer">
    <div class="select-header">
      <div
        :class="[
          'icon-container',
          'icon-left',
          { 'icon-disabled': disableDecrease },
        ]"
        v-on:click="decreaseDate"
      >
        <w-arrowleft></w-arrowleft>
      </div>
      <div
        class="adjustment-button datepicker-toggle-button"
        data-testid="picker-toggle"
        v-on:click="toggleDatepickerView"
      >
        {{ formattedDate }}
      </div>
      <div
        :class="[
          'icon-container',
          'icon-right',
          { 'icon-disabled': disableIncrease },
        ]"
        v-on:click="increaseDate"
      >
        <w-arrowright></w-arrowright>
      </div>
    </div>
    <div class="widget-booking-content-datepicker">
      <b-datepicker
        v-if="state.showMonthpicker"
        ref="datepicker"
        v-on:input="handleDateSelect"
        v-model="state.formValues.date"
        :month-names="monthsShort"
        inline
        :min-date="minMonth"
        type="month"
      >
        <template #header>
          <b-field />
        </template>
      </b-datepicker>
      <b-datepicker
        v-else
        ref="datepicker"
        v-on:input="handleDateSelect"
        v-model="state.formValues.date"
        v-bind:nearby-selectable-month-days="true"
        :first-day-of-week="1"
        inline
        :min-date="minDate"
        :events="events"
      >
        <template #header>
          <b-field />
        </template>
      </b-datepicker>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';
import ArrowLeft from './icons/ArrowLeft.vue';
import ArrowRight from './icons/ArrowRight.vue';

export default {
  components: {
    'w-arrowleft': ArrowLeft,
    'w-arrowright': ArrowRight,
  },
  props: ['state'],
  data: () => {
    return {
      nextMonthEvents: [],
    };
  },
  mounted() {
    if (this.events.length > 0 && !this.state.showMonthpicker) {
      this.addEventsForNextMonth();
    }
  },
  watch: {
    'state.showMonthpicker': function (showMonthpicker) {
      if (!showMonthpicker) {
        if (this.events.length > 0) {
          this.addEventsForNextMonth();
        }
      }
    },
    events: function (events) {
      if (events.length > 0 && !this.state.showMonthpicker) {
        this.addEventsForNextMonth();
      }
    },
  },
  computed: {
    date() {
      return this.state.formValues.date;
    },
    step() {
      return this.state.showMonthpicker ? 'year' : 'month';
    },
    isMonths: function () {
      return this.step === 'month';
    },
    isYears: function () {
      return this.step === 'year';
    },
    disableIncrease: function () {
      return !this.isDateWithinRange(this.nextDate);
    },
    disableDecrease: function () {
      return !this.isDateWithinRange(this.prevDate);
    },
    monthsShort: function () {
      return moment.monthsShort().map((month) => month.toUpperCase());
    },
    minDate: function () {
      return this.state.getJavascriptDate(moment().startOf('day'));
    },
    minMonth: function () {
      return this.state.getJavascriptDate(moment().startOf('month'));
    },
    formattedDate: function () {
      const format = this.isYears ? 'YYYY' : 'MMMM YYYY';
      return moment(this.date).local().format(format);
    },
    events: function () {
      const currentDate = moment(this.state.formValues.date).local();
      const nextDate = moment(currentDate).add(1, 'month');
      const pastDate = moment(currentDate).subtract(1, 'month');
      const lastDayOfPastMonth = pastDate.endOf('month').date();
      const pastEventDays = [lastDayOfPastMonth - 6, lastDayOfPastMonth];

      const currentEvents = this.getEvents(
        currentDate.year(),
        currentDate.month()
      );
      const nextEvents = this.getEvents(
        nextDate.year(),
        nextDate.month(),
        [0, 6]
      );
      const pastEvents = this.getEvents(
        pastDate.year(),
        pastDate.month(),
        pastEventDays
      );
      return [...currentEvents, ...nextEvents, ...pastEvents];
    },
    prevDate() {
      return moment(this.date)
        .local()
        .subtract(1, this.step)
        .startOf(this.step);
    },
    nextDate() {
      return moment(this.date).local().add(1, this.step).startOf(this.step);
    },
    currentDate() {
      return moment().local().startOf('day');
    },
  },
  methods: {
    toggleDatepickerView: function () {
      if (this.state.showMonthpicker) {
        this.state.showDatepicker = true;
        this.state.showMonthpicker = false;
      } else {
        this.state.showDatepicker = false;
        this.state.showMonthpicker = true;
      }
    },
    handleDateSelect: function (date) {
      let newDate = moment(date).local().startOf('day');
      const currentDate = moment().local().startOf('day');
      if (
        this.state.showMonthpicker &&
        newDate.year() === currentDate.year() &&
        newDate.month() === currentDate.month()
      ) {
        newDate = currentDate;
      }
      this.state.formValues.date = newDate.toDate();
      this.state.getAvailabilities();
      if (this.isMonths) {
        this.$parent.close();
      }
      this.state.showDatepicker = this.state.showMonthpicker ? true : false;
      this.state.showMonthpicker = false;
    },
    getEvents: function (year, month, range = [0, 31]) {
      if (
        this.state.showMonthpicker ||
        this.state.facilityPromoTimes.length === 0
      ) {
        return [];
      }

      const formatWeekday = (day) => (day + 6) % 7;

      const events = [];

      // Group only the needed promo times by weekday for efficient lookup
      const promoTimesByWeekday = this.groupPromoTimesByWeekday(
        year,
        month,
        range
      );

      for (let i = range[0]; i < range[1]; i++) {
        const currentDate = new Date(year, month, 1 + i);
        const currentWeekday = formatWeekday(currentDate.getDay());

        // Check if there are valid promos for the given weekday
        if (promoTimesByWeekday?.[currentWeekday]) {
          const hasValidPromo = this.checkValidPromoForWeekday(
            promoTimesByWeekday[currentWeekday],
            currentDate
          );

          // Add valid events to array
          if (hasValidPromo) {
            events.push({
              type: 'is-warning',
              date: currentDate,
            });
          }
        }
      }

      return events;
    },
    groupPromoTimesByWeekday: function (year, month, range) {
      if (this.state.facilityPromoTimes.length === 0) {
        return null;
      }

      const currentDate = moment({ year, month, day: 1 });

      // Filter and retrieve valid promo times within the specified range for the month
      const validPromoTimes = this.state.facilityPromoTimes.filter((obj) => {
        const promoStartDate = moment(obj.start_date);
        const promoEndDate = moment(obj.end_date);

        const noDateRange = !obj.start_date || !obj.end_date;
        if (noDateRange) {
          return true;
        }

        const withinDateRange = currentDate.isBetween(
          obj.start_date,
          obj.end_date,
          'day',
          '[]'
        );
        if (withinDateRange) {
          return true;
        }

        const isInRange =
          promoEndDate.isSameOrAfter(
            moment(currentDate).add(range[0], 'days')
          ) &&
          promoStartDate.isSameOrBefore(
            moment(currentDate).add(range[1], 'days')
          );
        return isInRange;
      });

      // Group valid times by their respective weekdays for a more efficient retrieval
      const promoTimesByWeekday = {};
      for (const obj of validPromoTimes) {
        for (const weekday of obj.weekdays) {
          promoTimesByWeekday[weekday] = promoTimesByWeekday[weekday] || [];
          promoTimesByWeekday[weekday].push(obj);
        }
      }

      return promoTimesByWeekday;
    },
    checkValidPromoForWeekday: function (promoTimes, currentDate) {
      return promoTimes.find(
        (obj) =>
          !obj.start_date ||
          !obj.end_date ||
          moment(currentDate).isBetween(
            obj.start_date,
            obj.end_date,
            'day',
            '[]'
          )
      );
    },
    getWeekdayDates: function (year, month, weekday) {
      const dates = [];
      const date = new Date(year, month, 1);

      while (date.getMonth() === month) {
        if (date.getDay() === weekday) {
          dates.push(new Date(date));
        }
        date.setDate(date.getDate() + 1);
      }

      return dates;
    },
    addEventsForNextMonth: function () {
      // The reason we do this is because our component library doesn't let us add events to unfocused months by default.
      // To achieve a consistend styling, we therefore need to manipulate the DOM manually and set specific classnames for these cases.
      this.$nextTick(() => {
        const date = moment(this.state.formValues.date);
        const currentDate = moment();
        const nextMonthDate = moment(date).add(1, 'month');
        const pastMonthDate = moment(date).subtract(1, 'month');

        this.nextMonthEvents.forEach((element) => {
          element.classList.remove(
            'outside-month-event',
            'outside-month-off-day'
          );
        });

        this.nextMonthEvents = [];

        document.querySelectorAll('.is-nearby').forEach((item) => {
          const match = item.innerHTML.match(/\d+/g);
          if (!match) {
            return;
          }

          const day = parseInt(match[0], 10);
          if (Number.isNaN(day)) {
            return;
          }

          const isNextMonth = day >= 1 && day <= 7;
          const isPastMonth = day >= 24 && day <= 31;

          const dateToCheck = isNextMonth
            ? moment(nextMonthDate).date(day)
            : isPastMonth
              ? moment(pastMonthDate).date(day)
              : null;

          const isDayInPast = dateToCheck.isBefore(currentDate, 'day');
          if (isDayInPast || (!isNextMonth && !isPastMonth)) {
            return;
          }
          const matchingEvent = this.events.find((event) =>
            moment(event.date).isSame(dateToCheck, 'day')
          );

          if (matchingEvent !== undefined) {
            if (matchingEvent.type === 'is-link') {
              item.classList.add('outside-month-off-day');
              this.nextMonthEvents.push(item);
              return;
            } else {
              item.classList.add('outside-month-event');
              this.nextMonthEvents.push(item);
            }
          }
        });
      });
    },
    increaseDate: function () {
      if (this.disableIncrease) return;
      let newDate = this.nextDate;

      if (
        (this.isMonths || this.isYears) &&
        newDate.year() === this.currentDate.year() &&
        newDate.month() === this.currentDate.month()
      ) {
        newDate = this.currentDate;
      }

      this.updateDate(newDate);
    },
    decreaseDate: function () {
      if (this.disableDecrease) return;
      let newDate = this.prevDate;

      if (
        this.isMonths &&
        newDate.year() === this.currentDate.year() &&
        newDate.month() === this.currentDate.month()
      ) {
        newDate = this.currentDate;
      } else if (this.isYears && newDate.year() === this.currentDate.year()) {
        newDate = this.currentDate;
      }

      this.updateDate(newDate);
    },
    isDateWithinRange: function (date) {
      const currentDate = moment().local();

      const minDate = moment(currentDate).local();
      if (this.isMonths) {
        minDate.subtract(1, 'month');
      } else if (this.isYears) {
        minDate.subtract(1, 'year');
      }

      const maxDate = moment(currentDate).local().add(3, 'years');

      return date.isBetween(minDate, maxDate, 'day', '[]');
    },
    updateDate: function (newDate) {
      this.state.formValues.date = newDate.toDate();
      this.state.getAvailabilities();
      this.$emit('update');
    },
  },
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';
.select-header {
  width: 100%;
  padding-top: 1.5rem;
  display: flex;
  justify-content: space-around;
}
.icon-container {
  display: flex;

  &.icon-disabled {
    opacity: 0.4;
    svg {
      cursor: not-allowed !important;
    }
  }

  &.icon-left {
    color: var(--theme-accent-color);
    @at-root .widget-fullscreen & {
      padding-left: 0;
      margin-left: 10%;
    }
    @at-root #{$broadway} & {
      padding: 0.35rem;
      border: 1px solid var(--theme-accent-color);
      border-radius: 50%;
    }
  }

  &.icon-right {
    color: var(--theme-accent-color);
    @at-root .widget-fullscreen & {
      padding-right: 0;
      margin-right: 10%;
    }
    @at-root #{$broadway} & {
      padding: 0.35rem;
      border: 1px solid var(--theme-accent-color);
      border-radius: 50%;
    }
  }

  @at-root #{$broadway} & {
    display: block;
    color: var(--theme-accent-color);
    width: 20px;
    height: 20px;
    box-sizing: content-box;

    &:not(.icon-disabled):active {
      background: var(--theme-accent-color);
      color: var(--theme-background-color);
    }
  }
}
.adjustment-button {
  cursor: pointer;
  width: 8rem;
  text-align: center;
  color: var(--theme-accent-color);

  &.datepicker-toggle-button:hover {
    font-weight: 500;
  }
}
.widget-booking-content-datepicker {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;

  .dropdown-content {
    box-shadow: none;
    padding: 0;

    @at-root #{$broadway} &,
      #{$glass} & {
      background-color: transparent;
    }
  }

  @at-root #{$glass} & {
    background-color: transparent;
  }
}

.modal-content {
  max-height: 100% !important;
}
</style>
