import axios from 'axios';
import * as Sentry from '@sentry/browser';
import * as Bambora from '@bambora/checkout-sdk-web';

const checkoutMinWidth = 380; // current min width of the checkout modal

// transforms the checkout iframe to fit into the host (widget iframe or window)
function transformIframe(iframe) {
  if (window.innerWidth >= checkoutMinWidth) {
    iframe.style = null;
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    return;
  }
  iframe.style.width = iframe.style.minWidth = `${checkoutMinWidth}px`;
  const scale = window.innerWidth / iframe.clientWidth;
  const height = iframe.clientHeight / scale;
  const translateX = -(iframe.clientWidth - window.innerWidth) / 2 / scale;
  const translateY = -(height - iframe.clientHeight) / 2 / scale;
  iframe.style.height = `${height}px`;
  iframe.style.transform = `scale(${scale}) translate(${translateX}px, ${translateY}px)`;
}

class BamboraCheckout {
  constructor(facilityId) {
    this.facilityId = facilityId;
    this.sessionTokenUrl = `/v1/facility/${facilityId}/bambora/token`;
    this.transactionIdUrl = `/v1/facility/${facilityId}/bambora/token/set_transaction_id`;
  }

  checkout = (amount, customerEmail = null) =>
    new Promise(async (resolve, reject) => {
      try {
        const sessionToken = await this._getSessionToken(amount, customerEmail);
        const checkout = new Bambora.ModalCheckout(sessionToken);
        const resizeIframe = () => checkout.iframe.then(transformIframe);
        checkout.on(Bambora.Event.Cancel, () => resolve({ sessionToken }));
        checkout.on(Bambora.Event.Authorize, async (e) => {
          const transactionId = e.data.txnid;
          try {
            await this._setTransactionId(sessionToken, transactionId);
          } catch (e) {}
          await checkout.hide();
          checkout.destroy();
          window.removeEventListener('resize', resizeIframe);
          resolve({ sessionToken, transactionId });
        });
        await checkout.show();
        resizeIframe();
        window.addEventListener('resize', resizeIframe);
      } catch (e) {
        Sentry.captureException(e, {
          tags: { logger: 'BamboraCheckout/checkout' },
        });

        reject(e);
      }
    });

  _getSessionToken = async (amount, customerEmail) => {
    const response = await axios.post(this.sessionTokenUrl, {
      amount,
      facility_id: this.facilityId,
      customer_email: customerEmail,
    });
    return response.data.data.token;
  };

  _setTransactionId = async (sessionToken, transactionId) => {
    const response = await axios.post(
      `${this.transactionIdUrl}/${sessionToken}`,
      {
        transaction_id: transactionId,
      }
    );
    return response.status === 202;
  };
}

export default BamboraCheckout;
