<template>
  <div>
    <div class="list-select">
      <div
        v-for="option in options"
        v-on:click="handleClick(option)"
        :key="option.id"
        role="listitem"
      >
        {{ option.title }}
      </div>
    </div>
    <div class="guest-select-info" v-if="showAdditionalContent">
      <div>{{ txtGuestFooter }}</div>
      <a
        v-if="state.isPhonePreferredAndAvailable"
        :href="'tel:' + state.facilityPhone"
        >{{ state.facilityPhone }}</a
      >
      <a
        v-else-if="state.isEmailPreferredAndAvailable"
        :href="'mailto:' + state.facilityEmail"
        >{{ state.facilityEmail }}</a
      >
    </div>
  </div>
</template>

<script>
import i18n from '../../i18n';
export default {
  props: {
    state: {
      type: Object,
      required: false,
    },
    showAdditionalContent: {
      type: Boolean,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  computed: {
    txtGuestFooter: function () {
      const num = this.state.numOptions[this.state.numOptions.length - 1];
      return i18n.sprintf(
        i18n.gettext(
          'If you want to make a reservation for more than %s, please contact us directly.'
        ),
        i18n.sprintf(i18n.ngettext('1 guest', '%1d guests', num), num)
      );
    },
  },
  methods: {
    handleClick: function (option) {
      this.$emit('select', option);
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/_variables.scss';

.list-select {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1rem 0;
  height: 100%;
  border-radius: 10px;
  div {
    padding: 0.8rem;
    font-weight: 500;

    &:hover {
      cursor: pointer;
    }

    @at-root #{$broadway} & {
      &:hover {
        position: relative;
        z-index: 1;
        &::before {
          content: '';
          border: 1px solid var(--theme-accent-color);
          border-radius: 10px;
          background-color: transparent;
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: -1;
          top: 0;
          left: 0;
        }
      }
    }

    @at-root #{$glass} & {
      &:hover {
        position: relative;
        z-index: 1;
        &::before {
          content: '';
          background: var(--theme-accent-color);
          position: absolute;
          width: 120%;
          height: 100%;
          z-index: -1;
          top: 0;
          left: -10%;
          filter: brightness(1.05) blur(7px);
          border-radius: 10px;
        }
      }
    }
  }

  @at-root #{$broadway} & {
    border: 1px solid var(--theme-accent-color);
    border-radius: 20px;
    color: var(--theme-font-color);
  }

  @at-root #{$glass} & {
    background: var(--theme-background-color-opacity-5);
  }
}
.guest-select-info {
  padding: 1rem;
  font-size: 0.8rem;
  position: sticky;
  bottom: 0;
  border-top: 0.5px solid;
  background: var(--theme-background-color);

  @at-root #{$broadway} & {
    color: var(--theme-font-color);
  }
}
</style>
