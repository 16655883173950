<template>
  <w-picker
    class="date-picker"
    :label="formattedDate"
    @click="handleClick"
  ></w-picker>
</template>

<script>
import i18n from '../i18n';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';
import Picker from './common/Picker.vue';
import DatepickerModalContent from './DatepickerModalContent';

export default {
  components: {
    'w-picker': Picker,
  },
  props: ['state'],
  computed: {
    step() {
      return this.state.showDatepicker
        ? 'month'
        : this.state.showMonthpicker
          ? 'year'
          : 'day';
    },
    date() {
      return this.state.formValues.date;
    },
    isMonths: function () {
      return this.step === 'month';
    },
    isYears: function () {
      return this.step === 'year';
    },
    formattedDate: function () {
      if (moment(this.date).local().isSame(moment(), 'day')) {
        return i18n.gettext('Today');
      }
      return moment(this.date).local().format('DD.MM.YYYY');
    },
  },
  methods: {
    handleClick() {
      const modal = this.$modal.open({
        parent: this,
        component: DatepickerModalContent,
        props: {
          state: this.state,
        },
        customClass: `datepicker-modal ${this.state.widgetStyle}`,
        canCancel: ['escape', 'outside'],
      });
      modal.$on('close', () => (this.state.showMonthpicker = false));
    },
  },
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';

.date-picker {
  border-color: var(--theme-font-color-opacity-5);

  @at-root #{$broadway} & {
    border: none;
  }

  @at-root #{$glass} & {
    border: none;
    background: var(--theme-background-color-opacity-6);

    .icon-container {
      color: var(--theme-accent-color);
    }
  }
}
.datepicker-modal {
  .modal-background {
    opacity: 0.2;
  }
  .modal-content {
    background: var(--theme-background-color);
    color: var(--theme-font-color);
    max-width: 20rem !important;
    border-radius: 10px;

    .datepicker-content {
      padding-top: 0.5rem;
    }
  }
}

.broadway,
.glass {
  .modal-content {
    color: var(--theme-font-color);
    background: var(--theme-background-color) !important;
  }
}
</style>
