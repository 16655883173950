<template>
  <figure>
    <img
      v-if="image && !imageError"
      :data-url="image"
      :class="['image', { 'img-loaded': imageLoaded }, imageClass]"
      @load="handleImageLoad"
      @error="handleImageError"
      ref="img"
    />
    <div v-else-if="imageError">
      <slot name="fallback"></slot>
    </div>
  </figure>
</template>

<script>
export default {
  props: {
    state: {
      type: Object,
      required: false,
    },
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: false,
    },
    imageClass: {
      type: String,
      required: false,
    },
    dimensions: {
      type: Object,
      required: false,
    },
    showErrorMessage: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      imageLoaded: false,
      imageError: false,
      observer: null,
    };
  },
  computed: {
    image: function () {
      return this.src
        ? this.state.getResizedImageUrl(this.src, this.dimensions)
        : null;
    },
  },
  methods: {
    handleImageLoad: function () {
      this.imageLoaded = true;
    },
    handleImageError: function () {
      this.imageError = true;
    },
    loadImage: function () {
      const imageElement = this.$refs.img;
      if (imageElement) {
        imageElement.src = imageElement.dataset.url;
      }
    },
    setupIntersectionObserver() {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.loadImage();
            this.observer.unobserve(entry.target);
          }
        });
      });
      if (this.$refs.img) {
        this.observer.observe(this.$refs.img);
      }
    },
  },
  mounted() {
    this.setupIntersectionObserver();
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
};
</script>

<style lang="scss">
@import '../../styles/_variables.scss';

.image {
  opacity: 0;
  transition: opacity 0.5s ease;
  max-width: 100%;
  height: 100px;
  &.img-loaded {
    opacity: 1;
    height: auto;
  }
}
</style>
