<template>
  <div
    :class="[
      shouldBeExpandable && 'truncated-text',
      showFullDescription ? 'expanded' : 'collapsed',
      'expandable-text',
    ]"
  >
    <div class="text-container" ref="textContainer">
      {{ text }}
    </div>
    <div
      v-if="shouldBeExpandable"
      v-on:click="toggleDescription"
      :class="[
        'expand-text-btn',
        showFullDescription ? 'text-expanded-btn' : '',
      ]"
    >
      {{ txtExpandText }}
    </div>
  </div>
</template>

<script>
import i18n from '../../i18n';
export default {
  props: {
    text: {
      required: true,
    },
  },
  data() {
    return {
      showFullDescription: false,
      numberOfLines: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getNumberOfLines();
    });
  },
  computed: {
    shouldBeExpandable() {
      return this.numberOfLines >= 5;
    },
    txtExpandText: function () {
      return this.showFullDescription
        ? i18n.gettext('Show less')
        : i18n.gettext('Show more');
    },
  },
  methods: {
    toggleDescription: function () {
      this.showFullDescription = !this.showFullDescription;
    },
    getNumberOfLines: function () {
      const container = this.$refs.textContainer;
      if (!container) {
        return;
      }
      const containerHeight = container.clientHeight;
      const lineHeight = parseFloat(
        window.getComputedStyle(container).lineHeight
      );
      this.numberOfLines = Math.round(containerHeight / lineHeight);
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/_variables.scss';
.expandable-text {
  position: relative;
  line-height: 1.35;
}

.truncated-text {
  display: grid;
  transition: grid-template-rows 0.75s;

  & .text-container {
    overflow: hidden;
    min-height: 4.25rem;
  }
}
.truncated-text.collapsed {
  grid-template-rows: 0fr;
  transition-delay: 0.2s;
}
.truncated-text.expanded {
  grid-template-rows: 1fr;
}

.expand-text-btn {
  cursor: pointer;
  position: relative;
  display: flex;
  color: var(--theme-accent-color);
  font-size: 0.85rem;
  padding: 0.25rem;

  &::after {
    border: 1.5px solid currentColor;
    border-right: 0;
    border-top: 0;
    content: ' ';
    display: block;
    height: 9px;
    margin-top: 3px;
    margin-left: 7px;
    pointer-events: none;
    transform: rotate(-45deg);
    transform-origin: center;
    width: 9px;
  }

  &.text-expanded-btn::after {
    transform: rotate(135deg);
    margin-top: 7px;
  }
}
</style>
