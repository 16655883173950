<template>
  <div
    v-bind:class="[
      state.fullscreen ? 'widget-header-fullscreen' : 'widget-header',
    ]"
    v-if="state.step === 'BOOKING'"
  >
    <div v-if="state.facilityLogo" class="online-booking-facility-logo">
      <w-image
        :state="state"
        :src="state.facilityLogo"
        :alt="txtLogoAltText"
        :dimensions="{ width: 250 }"
      >
        <template #fallback>
          <p class="online-booking-facility-name">
            {{ txtFacilityName }}
          </p>
        </template>
      </w-image>
    </div>
    <div v-else-if="state.disableBranding" style="height: 0.5rem"></div>
    <p v-else class="online-booking-facility-name">
      {{ txtFacilityName }}
    </p>
    <div class="widget-booking-heading">
      {{ headerText }}
    </div>
    <div class="widget-booking-header">
      <w-guest-picker :state="state"></w-guest-picker>
      <w-date-picker :state="state"></w-date-picker>
      <w-room-picker
        v-if="state.displayRoomPicker"
        :state="state"
      ></w-room-picker>
    </div>
  </div>
  <div
    v-bind:class="[
      state.fullscreen ? 'widget-header-fullscreen' : 'widget-header',
    ]"
    v-else
  >
    <div class="online-booking-title">
      {{ txtStepName }}
    </div>
  </div>
</template>

<script>
import i18n from '../i18n';
import RoomPicker from './RoomPicker.vue';
import GuestPicker from './GuestPicker.vue';
import DatePicker from './DatePicker.vue';
import Image from './common/Image.vue';

export default {
  components: {
    'w-room-picker': RoomPicker,
    'w-guest-picker': GuestPicker,
    'w-date-picker': DatePicker,
    'w-image': Image,
  },
  props: ['state'],
  data: () => {
    return {
      headerText: i18n.gettext('Online Booking'),
      txtHeaderBooking: i18n.gettext('Booking'),
      txtHeaderEvents: i18n.gettext('Events'),
    };
  },
  computed: {
    txtFacilityName: function () {
      return this.state.facilityName;
    },
    txtLogoAltText: function () {
      return this.txtFacilityName + '-logo';
    },
    txtStepName: function () {
      switch (this.state.step) {
        case 'CUSTOMFIELDS':
          return i18n.gettext('Optional');
        case 'CONTACT':
          return i18n.gettext('Check out');
        case 'DEPOSIT':
          return i18n.gettext('Payment');
      }
      return '';
    },
  },
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';

.widget-booking-header {
  width: 100%;
  background: var(--theme-background-color);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  padding: 0 1rem;
  justify-content: space-between;

  @at-root #{$glass} & {
    background: transparent;
  }

  .guest-picker {
    grid-column: 1 / 2;
  }

  .date-picker {
    grid-column: 2 / 3;
  }

  .room-picker {
    grid-column: 1 / 3;
  }

  @at-root #{$glass} & {
    .guest-picker,
    .date-picker,
    .room-picker {
      background: var(--theme-background-color-opacity-7);
    }
  }
}

.widget-header-fullscreen {
  display: block;
  text-align: center;
}

.widget-header-fullscreen,
.widget-header {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  align-items: center;
}

.widget-header {
  flex: 0 0 auto;
  text-align: center;
}

.online-booking-facility-logo {
  img {
    max-height: 3.5rem;

    @media (min-width: $padding-medium) {
      max-height: 4.25rem;
    }
  }
}

.online-booking-facility-name {
  padding: 0.5rem 0;
  font-size: calc(var(--font-size) * 1.25);
  font-weight: 500;
}

.online-booking-title {
  padding: 0.75rem;
  text-transform: uppercase;
  color: var(--theme-font-color-opacity-5);

  @at-root #{$glass} &,
    #{$broadway} & {
    color: var(--theme-accent-color);
  }
}
.widget-booking-heading {
  padding-bottom: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  font-size: calc(var(--font-size) * 0.75);
  color: var(--theme-font-color-opacity-5);

  @at-root #{$glass} &,
    #{$broadway} & {
    color: var(--theme-accent-color);
  }
}
</style>
