<template>
  <div class="widget-deposit-stripe-fields">
    <div class="field">
      <b-radio
        native-value="CCARD"
        v-model="state.formValues.bookingDeposit.payment_type"
        v-on:input="onCardInput"
        v-bind:disabled="state.isSavingPayment || state.isSavingBooking"
      >
        {{ txtCreditCard }}
      </b-radio>
    </div>
    <div v-show="showCard" class="widget-stripe-card">
      <div class="widget-stripe-card-name">
        <b-field>
          <b-input
            class="widget-stripe-card-owner-name"
            v-bind:required="showCard"
            v-model="state.formValues.bookingDeposit.owner_name"
            type="text"
            v-bind:placeholder="txtOwnerName"
            v-bind:disabled="state.isSavingPayment || state.isSavingBooking"
          />
        </b-field>
      </div>
      <div class="widget-stripe-card-element">
        <div ref="ccard" id="ccard-element"><!-- stripe card --></div>
      </div>
    </div>
    <div class="field" v-if="state.facilityBookingDepositSepaEnabled">
      <b-radio
        native-value="SEPA-DD"
        v-model="state.formValues.bookingDeposit.payment_type"
        v-on:input="onSepaInput"
        v-bind:disabled="state.isSavingPayment || state.isSavingBooking"
      >
        {{ txtSepaCard }}
      </b-radio>
    </div>
    <div v-show="showIban" class="widget-stripe-iban">
      <div class="widget-stripe-iban-name">
        <b-field>
          <b-input
            v-bind:required="showIban"
            v-model="state.formValues.bookingDeposit.owner_name"
            type="text"
            v-bind:placeholder="txtOwnerName"
            v-bind:disabled="state.isSavingPayment || state.isSavingBooking"
          />
        </b-field>
      </div>
      <div class="widget-stripe-iban-email">
        <b-field>
          <b-input
            v-bind:required="showIban"
            v-model="state.formValues.bookingDeposit.owner_email"
            type="email"
            v-bind:placeholder="txtOwnerEmail"
            v-bind:disabled="state.isSavingPayment || state.isSavingBooking"
          />
        </b-field>
      </div>
      <div class="widget-stripe-iban-element">
        <div ref="iban" id="iban-element"><!-- stripe iban --></div>
      </div>
      <div class="widget-stripe-iban-bank"></div>
    </div>
  </div>
</template>

<script>
import i18n from '../i18n';

export default {
  props: ['state'],
  data: () => {
    return {
      txtCreditCard: i18n.gettext('Credit card'),
      txtSepaCard: i18n.gettext('Sepa direct debit'),
      txtPayPal: i18n.gettext('Paypal'),
      txtOwnerName: i18n.gettext('Owner name'),
      txtOwnerEmail: i18n.gettext('Owner email'),
    };
  },
  mounted: function () {
    this.$nextTick(function () {
      if (this.showCard) {
        this.onCardInput();
      } else if (this.showIban) {
        this.onSepaInput();
      }
    });
  },
  computed: {
    style: function () {
      return {
        base: {
          fontSize: '16px',
          fontFamily:
            "'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          color: this.state.textColor,
          '::placeholder': {
            color: '#CFD7DF',
          },
        },
      };
    },
    showCard: function () {
      return this.state.formValues.bookingDeposit.payment_type === 'CCARD';
    },
    showIban: function () {
      return this.state.formValues.bookingDeposit.payment_type === 'SEPA-DD';
    },
  },
  methods: {
    onCardInput: function (selected) {
      this.state.paymentError = false;
      const elements = this.state.stripe.elements();
      this.state.stripeCard = elements.create('card', {
        hidePostalCode: true,
        style: this.style,
      });
      this.state.stripeCard.mount('#ccard-element');
    },
    onSepaInput: function (selected) {
      this.state.paymentError = false;
      const elements = this.state.stripe.elements();
      this.state.stripeCard = elements.create('iban', {
        supportedCountries: ['SEPA'],
        style: this.style,
      });
      this.state.stripeCard.mount('#iban-element');
      this.state.stripeCard.on('change', this.onSepaChange);
    },
    onSepaChange: function (e) {
      if (e.bankName) {
        this.state.formValues.bankName = e.bankName;
      } else {
        this.state.formValues.bankName = '';
      }
    },
  },
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';

.widget-stripe-card-name,
.widget-stripe-iban-name,
.widget-stripe-iban-email,
.widget-stripe-iban-bank {
  padding-bottom: 6px;

  .help {
    display: none;
  }
}

.StripeElement {
  border-bottom: 1px solid var(--theme-accent-color);
  padding-top: 15px !important;
  height: calc(40px + (var(--font-size) / 1.5)) !important;
}

.widget-stripe-card-name,
.widget-stripe-card-name {
  padding: 7px 0px;
}

.widget-stripe-card-element,
.widget-stripe-iban-element {
  .help {
    display: none;
  }
}

.widget-stripe-card-name,
.StripeElement,
.StripeElement:hover,
.StripeElement:focus,
.StripeElement:active,
.StripeElement.StripeElement--focus {
  @at-root #{$broadway} & {
    border: 1px solid var(--theme-accent-color);
    border-color: var(--theme-accent-color);
    border-radius: 10px;
    margin: 10px 0px;
    border-bottom-color: var(--theme-accent-color);
    & .input {
      border-bottom: none;
    }
  }
}

.widget-deposit-stripe-fields {
  @at-root #{$broadway} & {
    border-top: none;
    color: var(--theme-font-color);
  }
}

.widget-stripe-card-name {
  @at-root #{$broadway} &,
    #{$glass} & {
    &.input {
      border-bottom-color: var(--theme-font-color);
    }

    &.control {
      & .input::placeholder {
        color: var(--theme-font-color);
        filter: brightness(0.65);
      }
    }
  }
}

.widget-stripe-card-owner-name {
  & .input::placeholder {
    color: var(--theme-font-color) !important;
    filter: brightness(0.65);
  }
}
</style>
