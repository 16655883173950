<template>
  <div class="field c-card-field">
    <b-radio
      native-value="BAMBORA"
      v-model="state.formValues.bookingDeposit.payment_type"
      v-bind:disabled="state.isSavingPayment || state.isSavingBooking"
    >
      {{ txtBambora }}
    </b-radio>
  </div>
</template>

<script>
import i18n from '../i18n';

export default {
  props: ['state'],
  data: () => {
    return {
      txtBambora: i18n.gettext('Credit card'),
    };
  },
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';
.c-card-field {
  color: var(--theme-accent-color);
}
</style>
