<template>
  <w-picker
    class="room-picker"
    :label="roompickerTitle"
    @click="handleClick"
  ></w-picker>
</template>

<script>
import Picker from './common/Picker.vue';
import ListSelect from './common/ListSelect.vue';

export default {
  components: {
    'w-picker': Picker,
  },
  props: ['state'],
  computed: {
    roomOptions: function () {
      return this.state.roomPickerOptions();
    },
    selectedRoomPickerOption: function () {
      return this.roomOptions.find(
        (option) => option.id === this.state.formValues.resourceGroup
      );
    },
    roomSelectOptions: function () {
      return this.state.roomPickerOptions();
    },
    roompickerTitle: function () {
      return this.selectedRoomPickerOption.title;
    },
  },
  methods: {
    handleClick() {
      const roomModalRef = this.$modal.open({
        parent: this.$parent,
        component: ListSelect,
        props: {
          options: this.roomSelectOptions,
        },
        events: {
          select: this.handleRoomSelect,
        },
        customClass: `roompicker-modal ${this.state.widgetStyle}`,
        canCancel: ['escape', 'outside'],
      });
      this.roomModalRef = roomModalRef;
    },
    handleRoomSelect: function ({ id }) {
      this.state.formValues.resourceGroup = id;
      this.state.getAvailabilities({ resource_group: id });
      this.roomModalRef.close();
    },
  },
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';

.room-picker {
  position: sticky;
  border-color: var(--theme-font-color-opacity-5);
  width: 100%;
  background: var(--theme-background-color);

  @at-root #{$broadway} & {
    border: none;

    .icon-left {
      margin-left: 2%;
    }
    .icon-right {
      margin-right: 2%;
    }
  }

  @at-root #{$glass} & {
    background: transparent;
    border: none;

    .icon-container {
      color: var(--theme-accent-color);
      &:not(.icon-disabled):active {
        color: var(--theme-background-color);
      }
    }
    .adjustment-button {
      color: var(--theme-accent-color);
    }
  }
}
.roompicker-modal {
  .modal-background {
    opacity: 0.2;
  }
  .modal-content {
    background: var(--theme-background-color);
    color: var(--theme-font-color);
    max-width: 20rem !important;
    max-height: 25rem;
    border-radius: 10px;
  }
}

.broadway,
.glass {
  .modal-content {
    color: var(--theme-font-color);
    background: var(--theme-background-color) !important;
  }
}
</style>
