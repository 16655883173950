<template>
  <div class="widget-fullscreen-details">
    <div class="widget-fullscreen-details-content">
      <button
        v-if="state.facilityWebsite"
        v-on:click="handleFacilityWebsiteClick"
        class="widget-fullscreen-details-back button is-primary"
      >
        {{ txtFacilityWebsite }}
      </button>
      <div v-else class="widget-fullscreen-details-facility-name">
        {{ txtFacilityName }}
      </div>
      <div
        v-if="state.resourceGroupName"
        class="widget-fullscreen-details-resource-group"
      >
        {{ state.resourceGroupName }}
      </div>
      <div v-if="openingHours" class="widget-fullscreen-details-times">
        <div class="widget-fullscreen-details-icon"><w-clock></w-clock></div>
        <div class="widget-fullscreen-details-hours">
          <div class="widget-fullscreen-details-heading">
            {{ txtOpeningTimes }}
          </div>
          <div
            v-for="(hour, index) in openingHours"
            :key="index"
            class="widget-fullscreen-details-hours-list"
          >
            <div class="widget-fullscreen-details-hours-days">
              {{ getHourDayRange(hour) }}
            </div>
            <div class="widget-fullscreen-details-hours-time">
              {{ getHourTimeRange(hour) }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="widget-fullscreen-details-loc-contact"
        :style="{
          display: state.isPhonePreferredAndAvailable ? 'flex' : 'block',
        }"
      >
        <div
          v-if="state.facilityStreet"
          class="widget-fullscreen-details-location"
        >
          <div class="widget-fullscreen-details-icon">
            <w-location></w-location>
          </div>
          <div class="widget-fullscreen-details-address">
            <div>
              {{ state.facilityStreet }}, {{ state.facilityZip }}
              {{ state.facilityCity }}
            </div>
          </div>
        </div>
        <div
          v-if="state.isPhonePreferredAndAvailable"
          class="widget-fullscreen-details-phone"
        >
          <div class="widget-fullscreen-details-icon"><w-phone></w-phone></div>
          <a
            :href="'tel:' + state.facilityPhone"
            class="widget-fullscreen-details-contact-details"
          >
            {{ state.facilityPhone }}
          </a>
        </div>
        <div
          v-else-if="state.isEmailPreferredAndAvailable"
          class="widget-fullscreen-details-email"
        >
          <div class="widget-fullscreen-details-icon"><w-email></w-email></div>
          <a
            :href="'mailto:' + state.facilityEmail"
            class="widget-fullscreen-details-contact-details"
          >
            {{ state.facilityEmail }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';
import i18n from '../i18n';
import Location from './icons/Location';
import Phone from './icons/Phone';
import Email from './icons/Email';
import Clock from './icons/Clock';

export default {
  components: {
    'w-location': Location,
    'w-phone': Phone,
    'w-email': Email,
    'w-clock': Clock,
  },
  props: ['state'],
  data: () => {
    return {
      txtOnlineBooking: i18n.gettext('ONLINE BOOKING'),
      txtOpeningTimes: i18n.gettext('Opening times'),
    };
  },
  computed: {
    txtFacilityWebsite: function () {
      return `${this.state.facilityName}`;
    },
    txtFacilityName: function () {
      return this.state.facilityName;
    },
    openingHours: function () {
      return this.state.facilityHours;
    },
  },
  methods: {
    handleFacilityWebsiteClick: function () {
      if (this.state.facilityWebsite) {
        window.open(this.state.facilityWebsite, '_blank');
      }
    },
    getHourDayRange: function (hour) {
      let isConsecutive = true;
      if (hour.weekdays.length > 1) {
        for (let i = 0; i < hour.weekdays.length - 1; i++) {
          let current = hour.weekdays[i] + 1;
          let next = hour.weekdays[i + 1];
          if (current !== next) {
            isConsecutive = false;
          }
        }
      } else {
        isConsecutive = false;
      }

      const weekdayNames = hour.weekdays.map(
        (weekday) => moment().localeData()._weekdaysMin[(weekday + 1) % 7]
      );
      const firstName =
        moment().localeData()._weekdaysMin[(hour.weekdays[0] + 1) % 7];
      const lastName =
        moment().localeData()._weekdaysMin[
          (hour.weekdays[hour.weekdays.length - 1] + 1) % 7
        ];
      return isConsecutive
        ? `${firstName} - ${lastName}`
        : weekdayNames.join(', ');
    },
    getHourTimeRange: function (hour) {
      return `${moment(hour.begins, 'HH:mm').format('LT')} - ${moment(
        hour.ends,
        'HH:mm'
      ).format('LT')}`;
    },
  },
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';

$spacing: 80px;
$left-width: 75px;
.widget-fullscreen-flex {
  position: absolute;
  right: 18%;
  height: 100%;
  @media (max-width: 899px) {
    position: initial;
    width: 100%;
    height: auto;
  }
  .widget-wrapper-fullscreen {
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media (max-width: $padding-medium) {
  .theme-styling-left,
  .theme-styling-right,
  .theme-styling-top {
    display: none;
  }
  .widget-wrapper-fullscreen {
    display: contents;
  }
  .widget-fullscreen {
    background: transparent;
  }
}

.widget-fullscreen-details-loc-contact {
  align-content: space-around;
  @media (max-width: 1440px) {
    flex-direction: column;
    & .widget-fullscreen-details-location,
    .widget-fullscreen-details-phone {
      padding-top: 20px;
    }
  }
}

.widget-fullscreen-details {
  flex: 0 1 100%;
  background: var(--theme-background-color);
  display: none;
  @media (min-width: $fullscreen-detail) {
    display: block;
  }
}

.widget-fullscreen-details-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 100%;
  margin: 0 15%;
  padding: calc($marginSize * 1.5);
  background: var(--theme-background-color);
  top: 29%;
  position: relative;
  border: 1px solid var(--theme-accent-color);

  @media (max-width: 1250px) {
    margin: 0 5%;
  }
  @media (max-width: 1100px) {
    margin: 0 auto;
    .widget-fullscreen-details-loc-contact,
    .widget-fullscreen-details-facility-name {
      max-width: 30%;
    }
  }

  @at-root #{$broadway} & {
    color: var(--theme-font-color);
    z-index: 1;
    background: var(--theme-background-color);
    border-color: var(--theme-accent-color);
    border-radius: 20px;
    color: var(--theme-font-color);
  }

  @at-root #{$glass} & {
    color: var(--theme-font-color);
    z-index: 1;
    background: var(--theme-background-color-opacity-6);
    backdrop-filter: blur(5px);
    border-color: var(--theme-background-color);
    border-radius: 10px;
  }
}

.widget-fullscreen-details-heading {
  font-weight: 600;
  font-size: 1.2rem;
}

.widget-fullscreen-details-facility-name {
  color: var(--theme-accent-color);
  position: relative;
  padding: 0rem 1rem;
  margin: 1rem;
  font-size: 1.8rem;
}

.widget-fullscreen-details-back {
  font-size: 1.7rem !important;
  position: relative;
  padding: 1rem 3rem !important;
  margin: 1rem;
  max-width: 40%;
  white-space: normal !important;
  word-break: break-word;
  hyphens: auto;

  &:hover {
    &::before {
      transform: translateX(-2.5px) rotate(45deg) scale(1.05);
      transition: 0.2s linear;
    }
  }

  &::before {
    border: 2px solid currentColor;
    border-right: 0;
    border-top: 0;
    content: ' ';
    display: block;
    height: 12px;
    margin-top: -5px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 25px;
    transform: rotate(45deg);
    transform-origin: center;
    width: 12px;
  }
}

.widget-fullscreen-details-icon {
  flex: 0 0 $left-width;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.widget-fullscreen-details-online-booking {
  opacity: 0.4;
  line-height: 1;
  padding: $spacing 0px 10px $left-width;
}

.widget-fullscreen-details-facility {
  font-size: 24px;
  display: flex;
  cursor: pointer;
}

.widget-fullscreen-details-times {
  padding-top: calc($spacing / 4);
  display: flex;
  max-width: 33%;
}

.widget-fullscreen-details-hours {
  flex: 0 1 auto;
  width: 100%;
}

.widget-fullscreen-details-hours-list {
  display: flex;
  width: 100%;
  margin-right: 10px;
  padding-top: 10px;
}

.widget-fullscreen-details-hours-days {
  flex: 1 1 40%;
  margin-right: 10px;
}

.widget-fullscreen-details-hours-time {
  flex: 1 1 60%;
  padding-right: 1rem;
}

.widget-fullscreen-details-location {
  padding-top: calc($spacing / 2);
  display: flex;
}

.widget-fullscreen-details-address {
  flex: 1 1 auto;
  padding: 5px 0px 10px 0px;
}

.widget-fullscreen-details-contact-details {
  flex: 1 1 auto;
  padding-top: 7px;
}

.widget-fullscreen-details-name {
  flex: 1 1 auto;
}

.widget-fullscreen-details-phone {
  padding-top: calc($spacing / 2);
  padding-bottom: 0.5rem;
  display: flex;
}

.widget-fullscreen-details-email {
  padding-top: calc($spacing / 3);
  padding-bottom: 0.5rem;
  display: flex;
}

.widget-fullscreen-details-resource-group {
  padding-left: 75px;
}
</style>
