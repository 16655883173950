<template>
  <w-external :state="state" :heading="txtHeading" :details="txtDetails">
    <div id="map" style="height: 200px;"></div>
  </w-external>
</template>

<script>
import i18n from '../i18n';
import External from './External';

export default {
  components: {
    'w-external': External,
  },
  props: ['state'],
  computed: {
    isBooking: function () {
      return this.state.booking.ref_num !== null;
    },
    txtHeading: function () {
      if (this.isBooking) {
        return i18n.gettext('We are looking forward to hosting you!');
      } else {
        return i18n.gettext(
          'You are still on our waitlist! We will contact you in case a table becomes available.'
        );
      }
    },
    txtDetails: function () {
      if (this.isBooking) {
        return i18n.gettext('Your booking details:');
      } else {
        return i18n.gettext('Your booking request details:');
      }
    },
  },
  mounted: function () {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        address: `${this.state.facilityName}, ${this.state.facilityCity}, ${this.state.facilityStreet}`,
      },
      (results, status) => {
        if (status !== 'OK' || !results[0]) {
          throw new Error(status);
        }

        this.map = new google.maps.Map(document.getElementById('map'), {
          center: results[0].geometry.location,
          scrollwheel: false,
          zoom: 15,
          disableDefaultUI: true,
        });
        this.map.setCenter(results[0].geometry.location);
        this.map.fitBounds(results[0].geometry.viewport);

        let marker = new google.maps.Marker({
          position: results[0].geometry.location,
        });
        marker.setMap(this.map);
      }
    );
  },
};
</script>
