<template>
  <div class="widget-external">
    <div v-if="heading" class="widget-external-heading">{{ heading }}</div>
    <div v-if="details || $slots.details" class="widget-external-details">
      <slot v-if="$slots.details" name="details"></slot>
      <span v-else>{{ details }}</span>
    </div>
    <div
      :class="{
        'widget-external-booking-info': true,
        'widget-external-booking-info-cancelled': cancelled,
      }"
    >
      <div v-if="isBooking" class="widget-external-info-line">
        <div class="widget-external-info-label">{{ txtBookingNumber }}</div>
        <div>{{ txtFormattedRefNum }}</div>
      </div>
      <div class="widget-external-info-line">
        <div class="widget-external-info-label">{{ txtName }}</div>
        <div>{{ txtFormattedName }}</div>
      </div>
      <div class="widget-external-info-line">
        <div class="widget-external-info-label">{{ txtDate }}</div>
        <div>{{ txtFormattedDate }}</div>
      </div>
      <div class="widget-external-info-line">
        <div class="widget-external-info-label">{{ txtNumberOfPeople }}</div>
        <div>{{ txtFormattedNum }}</div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';

import i18n from '../i18n';

export default {
  props: ['state', 'heading', 'details', 'cancelled'],
  data: function () {
    return {
      txtName: i18n.gettext('Name'),
      txtDate: i18n.gettext('Date, Time'),
      txtBookingNumber: i18n.gettext('Booking number'),
      txtNumberOfPeople: i18n.gettext('Number of people'),
    };
  },
  computed: {
    isBooking: function () {
      return this.state.booking.ref_num !== null;
    },
    txtFormattedRefNum: function () {
      return `#${this.state.booking.ref_num}`;
    },
    txtFormattedDate: function () {
      return moment(this.state.booking.date).format('lll');
    },
    txtFormattedName: function () {
      return this.state.booking.name;
    },
    txtFormattedNum: function () {
      return this.state.booking.num;
    },
  },
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';

.widget-external {
  overflow-y: auto;
  padding: 10px;
}

.widget-external-heading {
  color: var(--theme-accent-color);
  font-size: 1.2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.widget-external-details {
  margin-bottom: 2rem;
}

.widget-external-info-line {
  color: var(--theme-accent-color);
  display: flex;
}

.widget-external-info-label {
  font-weight: 400;
  margin-right: 0.5rem;
  width: 50%;
}

.widget-external-booking-info {
  margin-bottom: 1rem;
}

.widget-external-booking-info-cancelled .widget-external-info-line {
  color: $danger;
  text-decoration: line-through;
}
</style>
