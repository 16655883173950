import './styles/index.scss';

const hexToRgb = (hexString = '#FFFFFF') => {
  let hex = String(hexString).replace(/[^0-9a-f]/gi, '');
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const rgbString = result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}`
    : '0, 0, 0';
  return rgbString;
};

function adjustHexColor(hexColor, percent) {
  if (/^#([0-9a-fA-F]{3}){1,2}$/.test(hexColor)) {
    const hexColorNormalized =
      hexColor.length === 4
        ? hexColor
            .slice(1)
            .split('')
            .map((c) => c + c)
            .join('')
        : hexColor.slice(1);

    const adjustedRgb = [
      parseInt(hexColorNormalized.slice(0, 2), 16),
      parseInt(hexColorNormalized.slice(2, 4), 16),
      parseInt(hexColorNormalized.slice(4, 6), 16),
    ];

    adjustedRgb.forEach((value, index, array) => {
      const targetValue = percent >= 0 ? 255 : 0;
      array[index] = Math.round(
        value + ((targetValue - value) * Math.abs(percent)) / 100
      );
    });

    return `#${adjustedRgb
      .map((c) => c.toString(16).padStart(2, '0'))
      .join('')}`;
  } else {
    return hexColor;
  }
}

export const themes = {
  classic: {
    backgroundColor: '#ffffff',
    textColor: '#555555',
    accentColor: '#07a7c4',
    accentLightColor: '#83d3e2',
  },
  broadway: {
    backgroundColor: '#374166',
    textColor: '#ffffff',
    accentColor: '#ff806d',
    accentLightColor: '#fbbab0',
  },
  glass: {
    backgroundColor: '#ffffff',
    textColor: '#000',
    accentColor: '#6cc1ff',
    accentLightColor: '#b1dafa',
  },
};

const configureStyles = ({
  accentColor,
  backgroundColor,
  textColor,
  fontSize,
  fullscreen,
  widgetStyle,
  borderRadius,
  standardStyleOnly,
}) => {
  const sheet = document.createElement('style');

  const accentWithFallback = standardStyleOnly
    ? themes['classic'].accentColor
    : accentColor || themes[widgetStyle].accentColor;
  const backgroundWithFallback = standardStyleOnly
    ? themes['classic'].backgroundColor
    : backgroundColor || themes[widgetStyle].backgroundColor;
  const textWithFallback = standardStyleOnly
    ? themes['classic'].textColor
    : textColor || themes[widgetStyle].textColor;

  const rgbAccent = hexToRgb(accentWithFallback);
  const rgbBackground = hexToRgb(backgroundWithFallback);
  const rgbText = hexToRgb(textWithFallback);

  sheet.innerHTML += `
    :root {
      --font-size: ${!fullscreen ? fontSize || '16px' : '16px'};
      --border-radius: ${borderRadius}px;

      --theme-background-color: ${backgroundWithFallback};
      --theme-font-color: ${textWithFallback};
      --theme-accent-color: ${accentWithFallback};
      --theme-accent-light-color: ${
        adjustHexColor(accentColor, 40) || themes[widgetStyle].accentLightColor
      };

      --theme-font-color-opacity-5: rgba(${rgbText}, 0.5);
      --theme-font-color-opacity-6: rgba(${rgbText}, 0.6);

      --theme-background-color-opacity-5: rgba(${rgbBackground}, 0.5);
      --theme-background-color-opacity-6: rgba(${rgbBackground}, 0.6);
      --theme-background-color-opacity-7: rgba(${rgbBackground}, 0.7);

      --theme-accent-color-opacity-25: rgba(${rgbAccent}, 0.25);
      --theme-accent-color-opacity-4: rgba(${rgbAccent}, 0.4);
      --theme-accent-color-opacity-5: rgba(${rgbAccent}, 0.5);
      --theme-accent-color-opacity-6: rgba(${rgbAccent}, 0.6);
      --theme-accent-color-opacity-7: rgba(${rgbAccent}, 0.7);
      --theme-accent-color-opacity-75: rgba(${rgbAccent}, 0.75);
    }
  `;

  document.addEventListener(
    'DOMContentLoaded',
    () => {
      document.body.appendChild(sheet);
    },
    false
  );
};

export default configureStyles;
