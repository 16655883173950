<template>
  <div class="picker" v-on:click="click">
    <div class="adjustment-button" data-testid="picker-toggle">
      <div class="adjustment-button-content">
        <span class="button-text">{{ txtFormattedName }}</span>
        <span class="arrow-container">
          <w-arrowright class="arrow-down"></w-arrowright>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowRight from '../icons/ArrowRight.vue';

export default {
  components: {
    'w-arrowright': ArrowRight,
  },
  props: ['label', 'disableIncrease', 'disableDecrease'],
  computed: {
    txtFormattedName: function () {
      if (this.label.length >= 30) {
        return this.label.substring(0, 28) + '..';
      } else {
        return this.label;
      }
    },
  },
  methods: {
    click: function () {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/_variables.scss';

.picker {
  display: flex;
  align-items: center;
  border: 0.5px solid;
  border-radius: 10px;
  cursor: pointer;

  @at-root #{$broadway} & {
    justify-content: space-evenly;
    align-items: flex-start;
    padding-top: 0px;
  }

  .dropdown {
    width: 100%;
  }

  &:hover {
    border-color: var(--theme-accent-color);
  }

  .adjustment-button {
    width: 100%;
    color: var(--theme-accent-color);
    display: flex;

    .adjustment-button-content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 0.75rem;

      .button-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .arrow-container {
        display: flex;
        align-items: center;
      }
    }

    @at-root #{$broadway} & {
      border: 1px solid var(--theme-accent-color);
      border-radius: 20px;
    }
  }

  .arrow-down {
    transform: rotate(90deg);
    vertical-align: middle;
  }
}
</style>
