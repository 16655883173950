<template>
  <div class="widget-availabilities-container">
    <div v-if="isLoading" class="widget-spinner"></div>
    <div v-else-if="showError" class="widget-availabilities-error">
      <b-message type="is-danger">
        {{ txtError }}
      </b-message>
    </div>
    <div v-else-if="showClosed" class="widget-availabilities-error">
      <b-message type="is-danger">
        {{ txtClosed }}
      </b-message>
    </div>
    <div v-else-if="showNoAvailability" class="widget-availabilities-error">
      <b-message type="is-danger">
        {{ txtNoAvailability }}
      </b-message>
    </div>
    <div v-else class="widget-booking-content-availabilities">
      <div
        v-for="availability in timeFilteredAvailabilities"
        v-bind:key="availability.date"
        v-on:click="handleAvailabilityClick(availability)"
        v-bind:class="{
          'widget-booking-content-availability': true,
          'selected-availability': state.isSelectedAvailability(availability),
          'waitlist-availability': isWaitlistAvailability(availability),
          'disabled-availability':
            isDisabledAvailability(availability) &&
            !isWaitlistAvailability(availability),
        }"
      >
        <div class="availability-time" style="display: flex">
          <hour-glass
            v-if="isWaitlistAvailability(availability)"
            width="0.8em"
            height="0.8em"
            style="align-self: center"
          ></hour-glass>
          <span>{{ availability.date | formatTime }}</span>
        </div>
        <div
          v-if="isWaitlistAvailability(availability)"
          class="waitlist-availability-text availability-text"
        >
          <span
            v-if="availability.promo"
            v-html="txtAvailability(availability)"
          ></span>
        </div>
        <div
          v-bind:class="{
            'availability-text': true,
            'availability-text-link':
              isDisabledAvailability(availability) &&
              !isWaitlistAvailability(availability) &&
              !!state.facilityNotAvailableLink,
            'availability-discount': availabilityDiscount,
          }"
          v-else-if="
            availability.promo ||
            (isDisabledAvailability(availability) &&
              !isWaitlistAvailability(availability))
          "
        >
          <a
            v-if="
              isDisabledAvailability(availability) &&
              !isWaitlistAvailability(availability) &&
              !!state.facilityNotAvailableLink
            "
            class="accented"
            :href="state.facilityNotAvailableLink"
            :target="
              !/^(mailto|tel):/.test(state.facilityNotAvailableLink)
                ? '_blank'
                : undefined
            "
          >
            {{ txtAvailability(availability) }}
            <email-icon
              v-if="state.facilityNotAvailableLink.startsWith('mailto:')"
              style="vertical-align: middle; height: 1rem; width: 1rem"
            ></email-icon>
            <phone-icon
              v-else-if="state.facilityNotAvailableLink.startsWith('tel:')"
              style="vertical-align: middle; height: 1rem; width: 1rem"
            ></phone-icon>
            <link-icon
              v-else
              style="vertical-align: middle"
              width="0.75rem"
              height="0.75rem"
            ></link-icon>
          </a>
          <span v-else v-html="txtAvailability(availability)"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '../i18n';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';
import LinkIcon from './icons/Link.vue';
import Phone from './icons/Phone.vue';
import Email from './icons/Email.vue';
import HourglassIcon from './icons/Hourglass.vue';
import PromoModalContent from './PromoModalContent.vue';

export default {
  props: ['state'],
  data: () => {
    return {
      showDatepicker: false,
      txtNoAvailability: i18n.gettext(
        'There are no availabilities at this time.'
      ),
      txtError: i18n.gettext('There was a problem.'),
      txtModalButton: i18n.gettext('Book now'),
    };
  },
  updated() {},
  computed: {
    timeFilteredAvailabilities: function () {
      // get availabilities relevant to the current time - no filtering based on state
      return this.state.availabilities.filter((a) => {
        return moment(a.date) > moment().subtract(2, 'hours');
      });
    },
    partySizeTooBig: function () {
      return !!this.timeFilteredAvailabilities.find(
        (a) => a.available < this.state.formValues.num
      );
    },
    isLoading: function () {
      return this.state.isGettingAvailabilities;
    },
    txtWaitlist: function () {
      return i18n.gettext('Waitlist');
    },
    txtClosed: function () {
      const maxDays = this.state.facilityMaxBookInAdvanceDays;
      if (moment().add('days', maxDays).toDate() < this.state.formValues.date) {
        if (maxDays === 0) {
          return i18n.gettext(
            'Sorry, we do not allow for online bookings in advance.'
          );
        } else {
          return i18n.sprintf(
            i18n.gettext(
              'Sorry, we only allow for online bookings up to %s in advance.'
            ),
            i18n.sprintf(i18n.ngettext('1 day', '%1d days', maxDays), maxDays)
          );
        }
      } else {
        const formattedDate = moment(
          this.state.getYMDDate(this.state.formValues.date),
          'YYYY-MM-DD'
        ).format('LL');
        return this.state.facilityClosedText
          ? this.state.facilityClosedText.replace('%s', formattedDate)
          : i18n.sprintf(
              i18n.gettext('Sorry, we are closed on %s.'),
              formattedDate
            );
      }
    },
    showClosed: function () {
      return (
        !this.state.isGettingAvailabilities &&
        this.state.availabilities.length === 0
      );
    },
    showNoAvailability: function () {
      return (
        !this.state.isGettingAvailabilities &&
        !this.showClosed &&
        this.timeFilteredAvailabilities.length === 0
      );
    },
    showError: function () {
      return this.state.availabilityError;
    },
    availabilityDiscount: function (availability) {
      return (
        availability.promo &&
        !isDisabledAvailability(availability) &&
        !this.state.isSelectedAvailability(availability)
      );
    },
  },
  filters: {
    formatTime: function (date) {
      return moment(date).format('LT');
    },
  },
  methods: {
    handleAvailabilityClick: async function (availability) {
      let allowOpenPromoModal = true;
      if (
        this.isDisabledAvailability(availability) &&
        !this.isWaitlistAvailability(availability)
      ) {
        return;
      }
      if (this.isWaitlistAvailability(availability)) {
        this.state.setAddingWaitlist(true);
        await new Promise((resolve) => {
          this.$modal.open({
            component: PromoModalContent,
            props: {
              state: this.state,
              description: i18n.gettext(
                "We have currently reached our maximum capacity. Feel free to join our waitlist, and we'll notify you as soon as spots become available."
              ),
              showButton: true,
              waitlistModal: true,
              hasQueuedUpModal: availability.promo ? true : false,
            },
            customClass: `booking-options-promotion-modal ${
              this.state.widgetStyle
            } ${!this.state.fullscreen ? 'mobile-view-modal' : ''}`,
            events: {
              close: (closeType) => {
                if (closeType === 'escape') {
                  allowOpenPromoModal = false;
                }
                resolve();
              },
            },
          });
        });
      }
      if (allowOpenPromoModal && availability.promo) {
        const modalPromo = availability.promo;
        if (modalPromo.description || modalPromo.image) {
          this.$modal.open({
            component: PromoModalContent,
            props: {
              state: this.state,
              title: modalPromo.promo,
              description: modalPromo.description,
              imageSrc: modalPromo.image,
              showButton: true,
              waitlistModal: this.isWaitlistAvailability(availability),
            },
            customClass: `booking-options-promotion-modal ${
              this.state.widgetStyle
            } ${!this.state.fullscreen ? 'mobile-view-modal' : ''}`,
          });
        }
      }
      if (!this.isWaitlistAvailability(availability)) {
        this.state.setAddingWaitlist(false);
      }
      this.state.selectAvailability(availability);
    },
    isDisabledAvailability: function (availability) {
      return (
        availability.available <= 0 ||
        availability.available < this.state.formValues.num
      );
    },
    isWaitlistAvailability: function (availability) {
      if (
        this.state.facilityEnableOnlineWaitlist &&
        this.isDisabledAvailability(availability)
      ) {
        if (moment(availability.date) > moment()) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    isRequest: function () {
      if (this.state.facilityAutomaticallyConfirmBookings) {
        return false;
      } else {
        if (this.state.facilityAutomaticallyConfirmBookingsUntilGroupSize) {
          return (
            this.state.formValues.num >
            this.state.facilityBookingAutoConfirmationMaxGroupSize
          );
        }
        return true;
      }
    },
    txtAvailability: function (availability) {
      const widgetNotAvailableText = {
        not_available: i18n.gettext('not available'),
        fully_occupied: i18n.gettext('fully occupied'),
        no_table_available: i18n.gettext('no table available'),
        only_phone_booking: i18n.gettext('only phone bookings'),
        please_call_us: i18n.gettext('please call us'),
        please_order_online: i18n.gettext('please order online'),
        walk_in_seating_only: i18n.gettext('walk-in seating (only)'),
        check_availability_here: i18n.gettext('check availability here'),
        check_options_nearby: i18n.gettext('check options nearby'),
        visit_here_instead: i18n.gettext('visit here instead'),
        get_tickets_here: i18n.gettext('get tickets here'),
        send_us_an_email: i18n.gettext('send us an email'),
        just_come_by: i18n.gettext('just come by'),
        try_your_luck: i18n.gettext('try your luck'),
        request_via_form: i18n.gettext('request via form'),
      };
      const widgetBookingRequestText = {
        available: i18n.gettext('available'),
        on_request: i18n.gettext('on request'),
      };

      if (this.isDisabledAvailability(availability)) {
        if (this.isWaitlistAvailability(availability) && availability.promo) {
          return availability.promo.promo;
        } else {
          return widgetNotAvailableText[this.state.facilityNotAvailableText];
        }
      } else if (availability.promo) {
        return availability.promo.promo;
      } else if (this.isRequest() && this.state.facilityBookingRequestText) {
        return widgetBookingRequestText[this.state.facilityBookingRequestText];
      } else {
        return i18n.gettext('available');
      }
    },
  },
  components: {
    'link-icon': LinkIcon,
    'email-icon': Email,
    'phone-icon': Phone,
    'hour-glass': HourglassIcon,
  },
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';

.widget-availabilities-container {
  height: 100%;
}

.widget-booking-content-availabilities {
  display: grid;
  padding: 0 1.5rem;
  justify-items: center;
  grid-template-columns: repeat(
    auto-fit,
    minmax(min(50%, max(6.6em, 20%)), 1fr)
  );

  .widget-booking-content-availability {
    position: relative;
    align-items: center;
    border-bottom: none;
    justify-content: center;
    width: 6.6em;
    height: 4.3em;
    overflow-y: hidden;
  }
}

.widget-booking-content-availability {
  flex-direction: column;
  border-radius: 8px;

  @at-root #{$broadway} & {
    color: var(--theme-accent-color);
  }

  @at-root #{$glass} & {
    color: var(--theme-font-color);
    .widget-booking-content-availability:last-child::before {
      height: 75%;
    }
  }
}

.widget-availabilities-error {
  margin: 0px 10px;
  padding-top: 10px;
}

.widget-booking-content-availability:not(.disabled-availability) {
  cursor: pointer;
}

.disabled-availability {
  cursor: not-allowed;
}

.widget-booking-content-availability {
  display: flex;
  border-bottom: 1px solid $grey-lighter;

  &.disabled-availability,
  .availability-text-link,
  a {
    color: var(--theme-font-color);
    opacity: 0.7;

    &.availability-text-link:hover {
      opacity: 1;
    }
  }

  &.selected-availability {
    border-color: var(--theme-accent-color);
    color: white;
    background-color: var(--theme-accent-color);

    @at-root #{$broadway} & {
      border-color: var(--theme-accent-color);
      position: relative;
      background: transparent;
      z-index: 1;

      &::before {
        content: '';
        background: var(--theme-accent-color);
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: -1;
        top: 0;
        left: 0;
        filter: blur(1px);
        border-radius: 20px;
      }
    }

    @at-root #{$glass} & {
      position: relative;
      background: transparent;
      z-index: 1;
      border-color: var(--theme-accent-color);

      &::before {
        content: '';
        background: var(--theme-accent-color);
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: -1;
        top: 0;
        left: 0;
        filter: blur(7px);
      }
    }
  }
  &.selected-availability .availability-text {
    color: white;
  }

  &.waitlist-availability {
    opacity: 1;

    & .availability-time > span {
      text-align: center;
    }
  }

  .waitlist-availability-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.8em;
    @at-root #{$broadway} & {
      & span:nth-child(2) {
        color: var(--theme-font-color);
      }
    }
  }

  .availability-text {
    position: absolute;
    top: calc(100% / 1.6);
    text-align: center;
    color: var(--theme-accent-color);
    font-size: min(0.7em, 12px);
    line-height: 1;
    padding: 0px $marginSize;
    word-break: break-word;
    hyphens: auto;
  }

  .availability-time,
  .availability-text {
    @at-root #{$broadway} & {
      color: var(--theme-font-color);
    }
  }

  .availability-discount {
    font-size: 0.9rem;
    color: var(--theme-accent-color) !important;
  }
}
.selected-availability .availability-time {
  @at-root #{$broadway} & {
    color: var(--theme-background-color);
  }
}

.waitlist-availability:not(.selected-availability) {
  .waitlist-availability-text {
    & span:nth-child(2) {
      color: var(--theme-accent-color);
    }
  }
}
</style>
