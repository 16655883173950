const defaultLocale = 'en';

const CURRENCY_SHORTS = {
  EUR: '€',
  GBP: '£',
  SYP: '£',
  SHP: '£',
  LBP: '£',
  JEP: '£',
  IMP: '£',
  GGP: '£',
  GIP: '£',
  FKP: '£',
  EGP: '£',
  JPY: '¥',
  CNY: '¥',
  RUB: '₽',
  KPW: '₩',
  KRW: '₩',
  CHF: 'CHF',
};

export function getLocale(languageCode = defaultLocale) {
  return ['development', 'test'].includes(process.env.NODE_ENV)
    ? defaultLocale
    : languageCode.substring(0, 2);
}

export const localeFormattedPrice = (
  amount,
  { languageCode = 'en', currencyCode, ...otherOptions } = {}
) => {
  const numericValue = parseFloat(amount);

  const formatPrice = (value) => {
    if (!currencyCode) {
      console.error('Invalid currency code');
      return amount;
    }
    const formattedPrice = value.toLocaleString(languageCode, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      ...otherOptions,
    });
    return formattedPrice;
  };
  if (isNaN(numericValue)) {
    return formatPrice(0);
  }

  return formatPrice(numericValue);
};

export const getCurrencyShort = (currency) => {
  // Fallback covers USD; AUD, SGD and many more
  return CURRENCY_SHORTS[currency] || '$';
};
