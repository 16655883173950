<template>
  <w-external :state="state" :details="txtDetails">
    <div class="widget-external-radio-line">
      <b-radio
        native-value="confirmed"
        v-model="state.formValues.bookingResponse"
      >
        {{ txtConfirmed }}
      </b-radio>
      <b-radio
        native-value="cancelled"
        v-model="state.formValues.bookingResponse"
      >
        {{ txtCancelled }}
      </b-radio>
    </div>
    <div class="widget-external-additional-text">
      {{ txtAdditional }}
    </div>
    <div>({{ txtAddedToReceipt }})</div>
    <div>
      <w-input type="text" v-model="state.formValues.notes" />
    </div>
  </w-external>
</template>

<script>
import i18n from '../i18n';

import External from './External';
import InputField from './common/FormInputField';

export default {
  components: {
    'w-external': External,
    'w-input': InputField,
  },
  props: ['state'],
  data: function () {
    return {
      txtDetails: i18n.gettext('Set the booking status.'),
      txtConfirmed: i18n.gettext('Confirmed'),
      txtCancelled: i18n.gettext('Cancelled'),
      txtAdditional: i18n.gettext('Additional text or reason'),
      txtAddedToReceipt: i18n.gettext('added to the booking receipt email'),
    };
  },
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';

.widget-external-radio-line {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;

  label {
    width: 50%;
  }
}

.widget-external-additional-text {
  font-weight: bold;
  margin-top: 2rem;
}
</style>
