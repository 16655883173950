<template>
  <w-external :state="state" :heading="txtHeading"></w-external>
</template>

<script>
import i18n from '../i18n';
import External from './External';

export default {
  components: {
    'w-external': External,
  },
  props: ['state'],
  data: function () {
    return {
      txtHeading: i18n.gettext('This booking is confirmed.'),
    };
  },
};
</script>
